import React, { useState } from 'react';
import LandingHeader from '../components/shared/LandingHeader';
import LoadingIndicator from "../components/shared/LoadingIndicator";
import ErrorIndicator from "../components/shared/ErrorIndicator";
import { useApi } from '../callApi';
import Modal from '../components/shared/Modal';
import { useAuth0 } from '@auth0/auth0-react';
import SignUpModal from '../components/shared/SignupModal';
import tracker from "../tracker";

const PricingPage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const { isAuthenticated } = useAuth0();
  const [authModalOpen, setAuthModalOpen] = useState(false);


  // Define your pricing tiers
  const pricingTiers = [
    {
      name: "Basic",
      price: "$20",
      per: "month",
      features: [
        "Up to 500 generations",
        "Standard support",
        "Community access",
      ],
      planId: 'price_1OsO59BWfq3XHXwE2IiXzFG7'
    },
    {
      name: "Standard",
      price: "$40",
      per: "month",
      features: [
        "Up to 1,200 generations",
        "Priority support",
        "Community access",
        "Extended analytics",
      ],
      recommended: true,
    },
    {
      name: "Unlimited",
      price: "$100",
      per: "month",
      features: [
        "Unlimited generations",
        "24/7 support",
        "Dedicated account manager",
        "Advanced security features",
      ],
    },
  ];


  const { callApi } = useApi(false); // Assuming 'false' for private endpoints requiring auth

  const handleGetStartedClick = async (planId) => {
    if (!isAuthenticated) {
      // If user is not authenticated, show modal
      setAuthModalOpen(true);
      return;
    }
    tracker.track_event('subscribing', { planId: planId })

    setLoading(true); // Show the loading indicator
    setError(null); // Reset previous errors

    try {
      // Call the backend API to create a subscription
      const subscription = await callApi(`/user/subscription/create?plan_id=` + planId, {
        method: 'POST',
      }, {
        planId: planId,
      });

      // Handle the successful subscription here
      console.log('Subscription created:', subscription);
      setModalOpen(true)
      // Maybe redirect to a success page or show a success message
      setLoading(false); // Hide the loading indicator
    } catch (error) {
      // Handle errors here by displaying an error message
      console.error('Failed to create subscription:', error);
      setError(error.toString()); // Set the error state to the error message
      setLoading(false); // Hide the loading indicator
    }
  };

  if (loading) {
    return <LoadingIndicator fullscreen={true} message={'Processing...'} />;
  }

  if (error) {
    return <ErrorIndicator onClose={() => setError(null)} fullscreen={true} message={'Something went wrong!'} description={error} />;
  }

  return (
    <>
      <LandingHeader />
      <div className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Pricing</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Simple, transparent pricing
            </p>
            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
              Choose the plan that’s right for you.
            </p>
          </div>

          <div className="mt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-8">
              {pricingTiers.map((tier) => (
                <div key={tier.name} className={`relative p-6 border-2 ${tier.recommended ? "border-indigo-500" : "border-gray-300"} rounded-lg bg-white shadow-sm flex flex-col`}>
                  <dt>
                    <p className="text-lg leading-6 font-medium text-gray-900">{tier.name}</p>
                  </dt>
                  <dd className="mt-2">
                    <p className="text-3xl font-semibold">{tier.price}</p>
                    <p className="text-gray-500">{tier.per}</p>
                  </dd>
                  <dd className="mt-4">
                    <ul className="list-disc pl-5 space-y-2">
                      {tier.features.map((feature) => (
                        <li key={feature} className="text-gray-500">{feature}</li>
                      ))}
                    </ul>
                  </dd>
                  <dd className="mt-6">
                    <button onClick={() => handleGetStartedClick(tier.planId)} className={`w-full flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white ${tier.recommended ? "bg-indigo-600 hover:bg-indigo-700" : "bg-gray-600 hover:bg-gray-700"}`}>
                      {tier.price === "Custom" ? "Contact Us" : "Get started"}
                    </button>

                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
      <Modal title={'Congratulations !'} isOpen={modalOpen} onClose={() => setModalOpen(false)} >
        <h2>You are now on a Free Trial</h2>



      </Modal>
      <SignUpModal title={'Great Decision !'} message={"Let's sign you up first .. so you can start creating"} isOpen={authModalOpen} onClose={() => setAuthModalOpen(false)} />

    </>
  );
};

export default PricingPage;
