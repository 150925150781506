
import { useParams } from "react-router-dom";
import PodcastPreview from "../video-generator/PodcastPreview";

const PodcastPreviewPage = ({ }) => {


  return (
    <>



      <PodcastPreview />

    </>
  )

}
export default PodcastPreviewPage