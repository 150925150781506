import React, { useEffect, useState } from 'react';
import LandingHeader from '../../components/shared/LandingHeader';
import AppInput from '../../components/AppInput';
import { useApi } from '../../callApi';
import ErrorIndicator from '../../components/shared/ErrorIndicator';
import tracker from '../../tracker';
import LoadingIndicator from '../../components/shared/LoadingIndicator';
import Modal from '../../components/shared/Modal';
import { useParams } from 'react-router-dom';

const BrandEditorPage = () => {
  const { uuid } = useParams(); // Fetching UUID from URL params

  const { callApi } = useApi();
  const [loading, setLoading] = useState(false);
  const [brandData, setBrandData] = useState({});

  const [error, setError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  // Fetch brand data if UUID is present
  useEffect(() => {
    const fetchBrand = async () => {
      if (!uuid) return;
      setLoading(true);
      try {
        const response = await callApi(`/user/brands/${uuid}`);
        setBrandData(response.data); // Assuming response structure
      } catch (err) {
        console.error(err);
        setError(err.message || "Failed to fetch brand data");
      } finally {
        setLoading(false);
      }
    };

    fetchBrand();
  }, [uuid]);

  const saveBrand = async (inputData) => {
    if (!inputData)
      return alert('Invalid input');

    try {
      setLoading(true)
      setModalOpen(false)


      const response = await callApi(`/user/brands/save`, { 'method': 'POST' }, inputData);
      console.log(response)
      window.location = '/user/brands'
      //setOutput(response);

      tracker.track_event('save_brand', { "succesful": true })
    } catch (err) {
      console.error(err); // For debugging purposes
      const errorMessage = err.message || "An unexpected error occurred";
      setError(errorMessage);
      setModalOpen(true)
      tracker.track_error('Error savning brand ', errorMessage)

    } finally {
      setModalOpen(false)

      setLoading(false)
    }
  };

  const inputs = [
    // {
    //   "name": "url",
    //   "type": "url",
    //   "label": "Website URL",
    //   "tooltip": "Upload the Website URL you want to convert to a website.",
    //   "placeholder": "https://example.com"
    // },
    {
      "name": "name",
      "type": "text",
      "label": "Brand Name",
      "className": "col-span-full",
      "placeholder": "Your Brand Name."
    },
    {
      "name": "logo_url",
      "type": "file",
      "label": "Logo",
      "className": "col-span-full",
      "placeholder": "Your Brand Name."
    },
    {
      "name": "themeColor",
      "type": "color",
      "label": "Theme Color",
      "tooltip": "Choose a theme color for your slides."
    },
    {
      "name": "text",
      "type": "textarea-with-voice",
      "label": "Write in Text or Speak",
      "className": "col-span-full",
      "placeholder": "Describe your podcast."
    },

  ]

  return (
    <>

      <LandingHeader />
      <section className="py-4 overflow-hidden">
        <div className="container mx-auto px-4">
          <div className="bg-white p-6 border rounded-xl shadow">
            <h3 className="text-xl font-semibold text-gray-800 mb-5">{brandData?.name || 'New Brand'}</h3>

            <div className="w-full">

              {loading && <LoadingIndicator />}
              {!loading && <AppInput setDisableGeneration={() => { }} data={brandData} onGenerateClicked={saveBrand} inputs={inputs} />
              }

            </div>
          </div>
        </div>
      </section>
      <Modal title={''} isOpen={modalOpen} onClose={() => setModalOpen(false)} >
        {error &&
          <ErrorIndicator onClose={() => setError()} message={'Something went wrong !'} description={error} />
        }
      </Modal>
    </>


  );
};

export default BrandEditorPage;
