import React from 'react';



const RenderFHIRData = ({ data }) => {
  // Function to render the value based on its type
  const renderValue = (value) => {
    if (value === null || value === undefined) {
      // Handle null or undefined by returning a default string
      return <span>N/A</span>;
    } else if (Array.isArray(value)) {
      // Handle arrays by iterating over elements
      return (
        <ul>
          {value.map((item, index) => (
            <li key={index}>
              {renderValue(item)} {/* Recursively render each item */}
            </li>
          ))}
        </ul>
      );
    } else if (typeof value === 'object') {
      // Handle objects (except null) by rendering their properties
      return <RenderFHIRData data={value} />;
    } else {
      // Directly render strings, numbers, and booleans
      return <span>{value.toString()}</span>;
    }
  };

  // Ensure data is an object before trying to render its entries
  if (typeof data !== 'object' || data === null) {
    return <span>{String(data)}</span>;
  }

  return (
    <div className="bg-white shadow rounded px-4 py-2 mb-2">
      {Object.entries(data).map(([key, value]) => (
        <div key={key} className="mb-2">
          <strong>{key}:</strong> {renderValue(value)}
        </div>
      ))}
    </div>
  );
};



const FHIRCard = ({ data }) => {
  console.log(data)
  return (
    <div className="max-w-md mx-auto bg-gray-100 rounded-xl shadow-md overflow-hidden md:max-w-2xl">
      <div className="md:flex">
        <div className="p-8">

          <RenderFHIRData data={data} />
        </div>
      </div>
    </div>
  );
};

export default FHIRCard;
