import React from 'react';

const AppCard = ({ app, onTryNow, iconMode = 'output' }) => {
  const icon = iconMode == 'output' ? app.output_icon : app.input_icon
  const text = iconMode == 'output' ? app.name : app.name

  return (
    <div className="border bg-white rounded-lg shadow-sm hover:shadow transition-shadow duration-200 ease-in-out">
      <div className="p-4 flex items-center">

        <img src={`/assets/images/${icon}`} alt={app.name} className="h-12 w-12" />

        <div className="ml-4">
          <h4 className="text-lg font-medium text-gray-900 capitalize">{text}</h4>
          <p className="text-gray-500 text-sm">{app.description || ''}</p>
        </div>
      </div>
      <div className="px-4 py-3 bg-gray-50 rounded-b-lg flex justify-end">
        <button
          onClick={() => onTryNow(app)}
          className="text-black bg-white border-2 border-black font-medium rounded-lg text-sm px-5 py-2.5 transition-all duration-300 hover:border-yellow-500 hover:shadow"
        >
          Try Now
        </button>
      </div>
    </div>
  );
};

export default AppCard;
