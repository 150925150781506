import React from 'react';
import Confetti from 'react-confetti';

const PaymentSuccessPage = () => {

  return (
    <div className="text-center p-10">
      <Confetti

        numberOfPieces={450}
        recycle={false}
      />
      <h1 className="text-3xl font-bold mb-4">Payment Successful!</h1>
      <p className="text-xl mb-8">Thank you for your purchase.</p>
      <button
        onClick={() => window.location.href = '/'}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Continue to Dashboard
      </button>
    </div>
  );
};

export default PaymentSuccessPage;
