import LandingHeader from "../components/shared/LandingHeader";
import { useParams } from "react-router-dom";
import { useApi } from '../callApi';
import React, { useEffect, useState } from "react";
import AppOutputPreview from "../components/AppOutputPreview";
import LoadingIndicator from '../components/shared/LoadingIndicator';

const AppRunPage = ({ }) => {
  const { runId } = useParams(); // Retrieve the `runId` parameter from the URL
  const { callApi } = useApi();
  const [output, setOutput] = useState();
  const [currentApp, setCurrentApp] = useState()
  const [currentRun, setCurrentRun] = useState()

  const [loading, setLoading] = useState(false);

  const fetch = async () => {
    try {
      setLoading(true);
      const response = await callApi(`/user/runs/` + runId);
      setOutput(response.data);
      setCurrentApp(response.apps)
      setCurrentRun(response)

      setLoading(false);

    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetch();
  }, []);

  return (
    <>

      <LandingHeader />
      {loading && <LoadingIndicator />}


      {output && <AppOutputPreview run={currentRun} runId={runId} appId={currentApp?.id} output={output} outputType={currentApp.output_type} />}

    </>
  )

}
export default AppRunPage