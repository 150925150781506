import React, { useState, useEffect } from 'react';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
import { Bar, Pie, Doughnut } from 'react-chartjs-2';
import alasql from 'alasql';

const LinkedinCharts = ({ tableName }) => {
  const [companyDistribution, setCompanyDistribution] = useState({ datasets: [], labels: [] });
  const [topCompanies, setTopCompanies] = useState({ datasets: [], labels: [] });
  const [techCompaniesDistribution, setTechCompaniesDistribution] = useState({ datasets: [], labels: [] });

  useEffect(() => {
    // Fetches and sets data for company distribution
    const fetchCompanyDistribution = async () => {
      const query = `SELECT Company AS label, COUNT(*) AS counter FROM ${tableName} WHERE Company IS NOT NULL GROUP BY Company ORDER BY counter DESC`;
      const result = await alasql.promise(query);
      if (result && result.length) {
        setCompanyDistribution({
          labels: result.map(r => r.label),
          datasets: [{
            label: 'Number of Connections',
            data: result.map(r => r.counter),
            backgroundColor: 'rgba(75, 192, 235, 0.5)',
          }]
        });
      }
    };

    // Fetches and sets data for top 5 companies
    const fetchTopCompanies = async () => {
      const query = `SELECT TOP 5 Company AS label, COUNT(*) AS counter FROM ${tableName} WHERE Company IS NOT NULL GROUP BY Company ORDER BY counter DESC`;
      const result = await alasql.promise(query);
      if (result && result.length) {
        setTopCompanies({
          labels: result.map(r => r.label),
          datasets: [{
            label: 'Top 5 Companies',
            data: result.map(r => r.counter),
            backgroundColor: [
              '#FF6384',
              '#36A2EB',
              '#FFCE56',
              '#cc65fe',
              '#ff6348'
            ],
          }]
        });
      }
    };

    // Fetches and sets data for connections in specific tech companies
    const fetchTechCompaniesDistribution = async () => {
      const techCompanies = ['Google', 'Facebook', 'Amazon', 'Apple', 'Microsoft'];
      const query = `SELECT Company AS label, COUNT(*) AS counter FROM ${tableName} WHERE Company IN ('${techCompanies.join("','")}') GROUP BY Company ORDER BY counter DESC`;
      const result = await alasql.promise(query);
      if (result && result.length) {
        setTechCompaniesDistribution({
          labels: result.map(r => r.label),
          datasets: [{
            label: 'Connections in Top Tech Companies',
            data: result.map(r => r.counter),
            backgroundColor: [
              '#4285F4', // Google
              '#4267B2', // Facebook
              '#FF9900', // Amazon
              '#A2AAAD', // Apple
              '#F25022'  // Microsoft
            ],
          }]
        });
      }
    };

    fetchCompanyDistribution();
    fetchTopCompanies();
    fetchTechCompaniesDistribution();
  }, [tableName]);

  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-5">LinkedIn Connections - Company Insights</h2>
      <div className="grid md:grid-cols-2 gap-4">
        <div>
          <h3 className="font-semibold mb-3">Company Distribution</h3>
          <Bar data={companyDistribution} options={{ responsive: true }} />
        </div>
        <div>
          <h3 className="font-semibold mb-3">Top 5 Companies</h3>
          <Pie data={topCompanies} options={{ responsive: true }} />
        </div>
        <div>
          <h3 className="font-semibold mb-3">Connections in Top Tech Companies</h3>
          <Doughnut data={techCompaniesDistribution} options={{ responsive: true }} />
        </div>
      </div>
    </div>
  );
};

export default LinkedinCharts;
