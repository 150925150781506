import React, { useEffect, useState, useContext } from "react";
import { useApi } from '../../callApi';
import { SubscriptionContext } from '../../ProtectedRoute'; // Import the SubscriptionContext

const TrialAnnouncementBar = ({ isAuthenticated }) => {
  const { callApi } = useApi(false); // Assuming false for endpoints requiring authentication
  const [daysLeft, setDaysLeft] = useState(0);
  const isBetaDomain = window.location.hostname === 'beta.makegen.ai';
  const data = useContext(SubscriptionContext); // Access subscription data from the context

  if (!isAuthenticated || isBetaDomain) {
    return;
  }

  if (data.status != 'trialing')
    return


  if (isBetaDomain) {
    return (
      <div className="bg-indigo-600 text-white text-center p-3">
        <p>You are now using the beta version of MakeGen.</p>
      </div>
    );
  }
  if (!isAuthenticated || data.daysLeft <= 0) {
    return null; // Don't show the bar if the user is not authenticated or the trial has ended
  }



  return (
    <div className="bg-indigo-600 text-white text-center p-3">
      <p>Your trial ends in <span className="font-bold">{data.daysLeft} days{data.daysLeft !== 1 ? '' : 's'}</span>. Enjoy your trial!</p>
      {/* <CheckoutButton priceId={''} /> */}
    </div>
  );
};

export default TrialAnnouncementBar;
