import { useAuth0 } from "@auth0/auth0-react";

export function useApi(publicapi) {
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const callApi = async (endpoint, options = {}, body = null, queryParams = {}) => {
    try {
      let accessToken = null;
      if (!publicapi && isAuthenticated && !isLoading) {
        accessToken = await getAccessTokenSilently();
      }


      // Append query parameters to the endpoint
      const urlParams = new URLSearchParams(queryParams).toString();
      const url = `${apiBaseUrl}${endpoint}${urlParams ? `?${urlParams}` : ''}`;

      // Set up the fetch options
      const fetchOptions = {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };

      // Add the body to the fetch options if it's a POST request
      if (body && options.method === 'POST') {
        fetchOptions.body = JSON.stringify(body);
      }

      const response = await fetch(url, fetchOptions);
      if (!response.ok) {
        // Attempt to read the error message from the response body
        let errorMsg = `HTTP error! status: ${response.status}`;
        try {
          const errorBody = await response.json();
          errorMsg += `: ${errorBody.detail}`; // Assuming the server sends back an error message under "message"
        } catch (e) {
          // Response couldn't be parsed as JSON or didn't have an error message
        }

        throw new Error(errorMsg);
      }
      return await response.json();
    } catch (error) {
      console.error('Error making API call', error);
      throw error;
    }
  };
  const uploadFile = async (endpoint, fileData, queryParams = {}) => {
    try {
      let accessToken = '';
      if (!publicapi) {
        accessToken = await getAccessTokenSilently();
      }

      const urlParams = new URLSearchParams(queryParams).toString();
      const url = `${apiBaseUrl}${endpoint}${urlParams ? `?${urlParams}` : ''}`;

      const fetchOptions = {
        method: 'POST',
        headers: {
          ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
        },
        body: fileData,
      };

      const response = await fetch(url, fetchOptions);
      const clonedResponse = response.clone(); // Clone the response for debugging
      const responseBody = await clonedResponse.text(); // Read the cloned response body as text
      //alert(`Response status: ${response.status}, Body: ${responseBody}`); // Debug message

      if (response.ok) {
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          return await response.json(); // If response is JSON, parse it as such
        } else {
          return responseBody; // If response is not JSON, return the raw text
        }
      } else {
        throw new Error(`HTTP error! Status: ${response.status}: ${responseBody}`);
      }
    } catch (error) {
      console.error('Error uploading file', error);
      alert(`Error uploading file: ${error}`); // Alert the error for debugging
      throw error;
    }
  };

  return { callApi, uploadFile }
}
