import SlidesViewer from "../components/viewers/SlidesViewer";
import LandingHeader from "../components/shared/LandingHeader";
import { useParams } from "react-router-dom";

const SlidesViewerPage = ({ }) => {
  const data = {
    "slidesHtmlContent": [
      "<section style='background-color: #229954; color: #ffffff; text-align: center;'><h2 style='font-weight: bold;'>Discover Egypt</h2><p>Exploring the wonders of ancient civilization.</p><img src='https://source.unsplash.com/featured/?egypt' alt='Egypt' style='max-width: 80%; border-radius: 10px;'></section>",
      "<section style='background-color: #229954; color: #ffffff; text-align: left; padding: 40px;'><h2>The Pyramids of Giza</h2><p>Unraveling the mysteries of the ancient world.</p><img src='https://source.unsplash.com/featured/?pyramids' alt='Pyramids' style='width: 100%; max-width: 400px; margin-top: 20px; border-radius: 5px;'></section>",
      "<section style='background-color: #229954; color: #ffffff; text-align: right; padding: 40px;'><h2>Nile River Cruise</h2><p>Embarking on a journey through Egypt's lifeline.</p><video controls style='max-width: 90%; margin-top: 20px; border-radius: 5px;' src='https://makegen.ai/video?query=nile%20river'></video></section>"
    ],
    "revealConfig": {
      "controls": true,
      "progress": true,
      "slideNumber": true,
      "history": false,
      "keyboard": true,
      "overview": true,
      "center": true,
      "hash": false,
      "respondToHashChanges": false,
      "touch": true,
      "loop": false,
      "rtl": false,
      "shuffle": false,
      "fragments": true,
      "embedded": false,
      "help": true,
      "showNotes": false,
      "autoSlide": 0,
      "autoSlideStoppable": true,
      "mouseWheel": false,
      "hideAddressBar": true,
      "previewLinks": false,
      "transition": "slide",
      "transitionSpeed": "default",
      "backgroundTransition": "fade",
      "viewDistance": 3,
      "parallaxBackgroundImage": "",
      "parallaxBackgroundSize": "",
      "theme": "YourCustomTheme"
    }
  }


  return (
    <>



      <SlidesViewer data={data} />

    </>
  )

}
export default SlidesViewerPage