import React, { useState, useEffect } from 'react';
import { MdPerson } from 'react-icons/md';
import { useApi } from '../../callApi';
import VoiceRecoder from '../VoiceRecorder';
import FileUploader from '../FileUploader';

export default function ChatComponent({ isStreaming = false, streamingUrl = false, initialMessages = [], apiCall, data, enableVoice = true, enableUpload = true, containerClassName = "w-full", demo, title = "", subtitle, showPrompts, chatPrompts = [], onResponse, placeholder = "Type your message here...", textInputCss }) {
  const [messages, setMessages] = useState(initialMessages);
  const [inputCode, setInputCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [prompts, setPrompts] = useState(chatPrompts);
  const [summary, setSummary] = useState(chatPrompts);

  const [file, setFile] = useState(null);
  const [eventSource, setEventSource] = useState(null); // For streaming responses
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const { callApi, uploadFile } = useApi(demo);


  const handleSend = async (input) => {
    const currentFile = file;
    setFile(null)
    let messageContent = inputCode.trim() || input || document.getElementById('chat-input').value.trim();
    if (!messageContent) return; // Avoid sending empty messages

    let thumbnail = ''
    if (currentFile) {
      // @TODO: we must check if the upload file is image to put thumbnail if not add placeholder
      thumbnail = `<img src='${currentFile}' width='128px'/>`

    }

    const content = messageContent + " " + thumbnail

    // Add human message to UI before sending
    setMessages(prevMessages => [...prevMessages, { type: 'human', content: content }]);
    setLoading(true);

    if (currentFile) {
      messageContent = messageContent + " " + currentFile
    }

    if (isStreaming) {
      if (eventSource) eventSource.close(); // Close previous connection if exists
      setMessages(prevMessages => [...prevMessages, { type: 'ai', content: '' }]);

      let url = `${apiBaseUrl}/chat_stream?api_key=*&corpus_id=${data?.corpus_id}&message=${encodeURIComponent(messageContent)}`
      url = streamingUrl ? `${apiBaseUrl}/${streamingUrl}?message=${encodeURIComponent(messageContent)}` : url
      const newEventSource = new EventSource(url, {
        withCredentials: true,
      });
      setEventSource(newEventSource);

      newEventSource.onmessage = event => {
        setMessages(prevMessages => {
          // Copy all messages except the last
          const messagesCopy = [...prevMessages];
          // Update the content of the last message
          messagesCopy[messagesCopy.length - 1] = {
            ...messagesCopy[messagesCopy.length - 1],
            content: messagesCopy[messagesCopy.length - 1].content + event.data
          };
          return messagesCopy;
        });
      };

      newEventSource.onerror = (error) => {
        if (newEventSource.readyState === EventSource.CLOSED) {
          setLoading(false);
          newEventSource.close();
        } else {
          setLoading(false);
          newEventSource.close();
        }


      };


      // No need to setLoading(false) here; it will be handled in onmessage or onerror
    } else {
      try {
        let response = null;
        if (!apiCall) {
          response = await callApi('/chat/', {
            method: 'POST',
          }, { message: messageContent, ...data });
        } else {
          response = await apiCall(messageContent, data);
        }
        setMessages(prevMessages => [...prevMessages, { type: 'ai', content: response.message }]);
      } catch (error) {
        console.error('Failed to send message:', error);
        setMessages(prevMessages => [...prevMessages, { type: 'ai', content: "Failed to send message." }]);
      } finally {
        setLoading(false);
      }
    }

    setInputCode(''); // Clear the input field after sending
  };


  const onClickPrompt = (prompt) => {
    setInputCode(prompt)
  }

  // Function to handle the user pressing the 'Enter' key
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && inputCode.trim()) {
      handleSend();
    }
  };

  // Function to handle input change
  const handleChange = (event) => {
    setInputCode(event.target.value);
  };

  const handleUpload = (url) => {
    //setInputCode(img)
    setFile(url)
    //setMessages(prevMessages => [...prevMessages, { type: 'human', content: img }]);

  }

  useEffect(() => {
    if (data) {
      console.log(data)
      setPrompts(data.prompts || [])
      setSummary(data.summary)
    }

  }, [data])




  return (
    <div className={`flex flex-col ${containerClassName} relative`}>
      <div className="chat-content flex flex-col min-h-[75vh] 2xl:min-h-[85vh]">
        {messages.length == 0 && (
          <div class="container text-center p-8">

            <h1 class="text-2xl font-bold text-neutral mb-8">{data?.title || title || "Let's iterate together .."}</h1>
            <a href={data?.originalUrl}>
              <img className='mx-auto' src={data?.thumbnailUrl} width={'200px'} />

            </a>


            <h3 class="text-xl text-neutral mt-0 mb-8">{subtitle || ''}</h3>
            <p class="text-sm text-neutral mt-0 mb-8">{summary || ''}</p>




            {showPrompts && (

              <div class="grid md:grid-cols-2 gap-4">
                {prompts.map((prompt, index) => (

                  <div key={index} class="bg-white p-4 rounded-lg shadow-lg cursor-pointer">
                    <span onClick={() => onClickPrompt(prompt)}  >
                      <p class="text-gray-800">{prompt} </p>
                    </span>


                  </div>

                ))}
              </div>

            )}

          </div>
        )}
        {messages.map((message, index) => (
          <div key={index} className={`flex w-full items-center mb-4 ${message.type === 'ai' ? 'flex-row-reverse' : ''}`}>
            <div className={`message-bubble ${message.type === 'ai' ? '' : message.type} flex p-5 ${message.type === 'human' ? 'bg-gray-100' : 'bg-gray-200'} rounded-lg w-full items-center`}>
              <div className={`avatar flex items-center justify-center ${message.type === 'human' ? '' : ''} h-10 w-10 mr-4 flex-shrink-0`}>
                {message.type === 'human' ? <MdPerson className="text-brand-500 w-5 h-5" /> : <img width={'20'} src='/assets/logos/logo.png' alt='' />}
              </div>

              <div className="flex-grow flex items-center">
                {message.type === 'ai' && !message.content && <div className="loading-dot"></div>}
                <span dangerouslySetInnerHTML={{ __html: message?.content }} className="text-navy-700 font-semibold text-sm md:text-md leading-6 md:leading-6" style={{ whiteSpace: 'pre-wrap' }}>


                </span>
              </div>
            </div>
          </div>))}

        <div className={`${textInputCss || ''} flex flex-col mt-5 justify-self-end`}>
          <div className="flex justify-between items-center mb-2">
            {enableUpload && <FileUploader input={{}} onFileSelect={(text) => { handleUpload(text) }} setDisableGeneration={() => { }} compactMode={true} />}
            {file && <img width={'64px'} height={'32px'} src={file} />}
            <input
              id="chat-input"
              autoComplete="off"
              className="input-box border-2 border-black rounded-full p-3.5 mr-2.5 text-sm font-medium flex-grow text-navy-700 placeholder-gray-500 focus:border-black"
              type="text"
              placeholder={placeholder}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              value={inputCode}
            />
            <button
              className="py-3 px-4 text-sm bg-black text-white hover:bg-opacity-90 rounded  text-2xl  font-medium"
              onClick={handleSend}
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Send'}
            </button>
            <VoiceRecoder onTranscribed={(text) => { handleSend(text) }} setDisableGeneration={() => { }} compactMode={true} />
          </div>
          <div className="flex justify-center items-center mt-2">
            <span className="text-xs text-center text-gray-500">
              This may produce inaccurate information about people, places, or facts.
            </span>
            <span className="text-xs text-navy-700 font-medium underline">
              Nov 12 Version
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
