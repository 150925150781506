import React from 'react';

const PaymentCancelPage = () => {
  return (
    <div className="text-center p-10">
      <h1 className="text-3xl font-bold mb-4">Payment Canceled</h1>
      <p className="text-xl mb-8">
        Your payment was not processed. If this was a mistake, please try again.
      </p>
      <button
        onClick={() => window.location.href = '/pricing'}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Return to Pricing
      </button>
    </div>
  );
};

export default PaymentCancelPage;
