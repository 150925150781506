import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useApi } from '../callApi';
import LoadingIndicator from '../components/shared/LoadingIndicator';
import ErrorIndicator from '../components/shared/ErrorIndicator';
import LandingHeader from '../components/shared/LandingHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import AppListContainer from '../components/AppListContainer';
const AppSelectorPage = () => {
  const { outputType, title } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);


  if (loading) {
    return <LoadingIndicator fullscreen={true} message="Loading apps..." />;
  }

  if (error) {
    return <ErrorIndicator fullscreen={true} message="Something went wrong!" />;
  }

  return (

    <>
      <LandingHeader />
      <AppListContainer title={`${title || outputType} Apps`} outputType={outputType} iconMode={'input'} />


    </>

  );
};

export default AppSelectorPage;
