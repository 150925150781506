import React, { useState } from 'react';
import LandingHeader from '../components/shared/LandingHeader';



const FAQPage = ({ }) => {
  const faqs = [
    {
      question: "What is MakeGen?",
      answer: "MakeGen is a cutting-edge platform that utilizes the power of generative AI to convert and transform media and document formats. From PDFs to podcasts, web pages to charts, and more, MakeGen streamlines the process of content creation and manipulation for a wide range of users."
    },
    {
      question: "How can I use MakeGen to convert a PDF to a podcast?",
      answer: "Simply upload your PDF document using our intuitive interface, select the 'PDF to Podcast' option, and let MakeGen's AI do the rest. Within minutes, you'll receive a podcast version of your document, ready for streaming or download."
    },
    {
      question: "Is there a limit to the size of the files I can convert?",
      answer: "Yes, to ensure optimal performance and user experience, we currently limit file sizes to 100MB for free users. Premium users enjoy a higher limit of 500MB per file."
    },
    {
      question: "Can I use MakeGen for commercial purposes?",
      answer: "Absolutely! MakeGen offers both free and premium plans tailored to fit various needs, including commercial use. Our platform can help you enhance your content creation process, saving you time and resources."
    },
    {
      question: "What types of conversions does MakeGen support?",
      answer: "MakeGen supports a diverse range of conversions including PDF to Podcast, PDF to Web, Web to Questions, Web to Chart, PDF to Reel, and more. We're constantly expanding our services to include more formats based on user feedback."
    },
    {
      question: "How does MakeGen ensure the privacy and security of my data?",
      answer: "Data privacy and security are our top priorities. MakeGen uses state-of-the-art encryption and security practices to protect your data throughout the conversion process. Your files are automatically deleted from our servers after the conversion is complete."
    },
    {
      question: "Do I need to install any software to use MakeGen?",
      answer: "No, MakeGen is entirely web-based, meaning you can access our platform and all its features directly through your browser without the need to install any software."
    },
    {
      question: "How can I access advanced features?",
      answer: "Advanced features are available to our premium subscribers. You can upgrade to a premium plan through your account dashboard, unlocking higher file size limits, priority processing, and access to new and experimental features."
    },
    {
      question: "What should I do if I encounter an issue or need support?",
      answer: "Our dedicated support team is here to help you. Please reach out through our support page with any questions or issues, and we'll ensure a prompt and helpful response."
    },
    {
      question: "How can I provide feedback or suggest new features?",
      answer: "We love hearing from our users and welcome your feedback and suggestions. Please use the feedback form on our website or contact us directly via email to share your thoughts."
    }
  ];
  // Function to toggle FAQ answer visibility
  const [openFAQ, setOpenFAQ] = useState(null);

  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      return setOpenFAQ(null);
    }
    setOpenFAQ(index);
  };

  return (
    <>
      <LandingHeader />

      <div className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="mb-12 text-center">
            <h1 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">Frequently Asked Questions</h1>
            <p className="mt-4 text-lg leading-6 text-gray-500">
              Everything you need to know to get started.
            </p>
          </div>
          <dl className="space-y-6 divide-y divide-gray-200">
            {faqs.map((faq, index) => (
              <div key={index} className={`${openFAQ === index ? 'bg-gray-100' : 'bg-white'} p-6 rounded-lg`}>
                <dt className="text-lg leading-6 font-medium text-gray-900 cursor-pointer" onClick={() => toggleFAQ(index)}>
                  {faq.question}
                </dt>
                <dd className={`mt-2 ${openFAQ === index ? 'block' : 'hidden'} text-base text-gray-500`}>
                  {faq.answer}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </>

  );
};

export default FAQPage;
