import React, { useEffect, useState } from 'react';
import AppListContainer from '../components/AppListContainer';
import LandingHeader from "../components/shared/LandingHeader";

const AppListPage = () => {

  const [currentApp, setCurrentApp] = useState();

  const handleAppSelect = (app) => {
    setCurrentApp(app);
    window.location = '/apps/' + app.app_slug + '/' + app.id
  };

  return (
    <>
      <LandingHeader />
      <AppListContainer onAppSelect={handleAppSelect} />
    </>

  );
};

export default AppListPage;
