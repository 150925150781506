import React, { useState, useEffect } from 'react';
import { ForceGraph2D } from 'react-force-graph';

const GraphViewer = ({ data }) => {
  const [graphData, setGraphData] = useState(data);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (data.nodes.length > 0) {
      console.log(data.nodes); // Log the data prop to inspect it

      setIsLoading(false);
    }
  }, [data]);

  return (
    <div>
      {isLoading ? (
        <p>Loading graph...</p>
      ) : (
        <ForceGraph2D
          graphData={graphData}
          nodeAutoColorBy="group"
          nodeLabel={'label'}
          // onNodeClick={handleNodeClick}
          // nodeCanvasObject={nodeCanvasObject}
          // linkCanvasObject={linkCanvasObject}
          enableZoomPanInteraction={true}
          enableNodeDrag={true}
        />
      )
      }
    </div >
  );
};

export default GraphViewer;
