import LandingHeader from "../components/shared/LandingHeader";
import { useParams } from "react-router-dom";
import { useApi } from '../callApi';
import React, { useEffect, useState } from "react";
import AppOutputPreview from "../components/AppOutputPreview";
import LoadingIndicator from '../components/shared/LoadingIndicator';
import MainAppGeneratorContainer from "../components/MainAppGeneratorContainer";
import ChatComponent from "../components/viewers/ChatComponent";

const AppRunIteratePage = ({ }) => {
  const { runId } = useParams(); // Retrieve the `runId` parameter from the URL
  const { callApi } = useApi();
  const [output, setOutput] = useState();
  const [currentApp, setCurrentApp] = useState()
  const [currentRun, setCurrentRun] = useState()



  const [loading, setLoading] = useState(false);

  const fetch = async () => {
    try {
      setLoading(true);
      const response = await callApi(`/user/runs/` + runId);
      setOutput(response.data);
      setCurrentApp(response.apps)
      setCurrentRun(response)

      setLoading(false);

    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const doSend = async (messageContent) => {

    setLoading(true)
    let data = {}
    const response = await callApi('/iterate', {
      method: 'POST',
    }, { message: messageContent, output: output, appSlug: currentApp?.app_slug });
    console.log(response)
    setOutput(response.output)
    setLoading(false)
    return response
  }


  useEffect(() => {
    fetch();
  }, []);

  return (
    <>
      <LandingHeader />
      {loading && <LoadingIndicator />}

      <div className="flex flex-col md:flex-row items-start gap-4">
        <div className="flex-1">
          {currentApp && currentRun && (
            <div className="h-full p-4 border-2 border-gray-300   bg-white"> {/* Adjust styles as needed */}
              <ChatComponent title="Let's iterate to make it perfect .." chatPrompts={currentApp?.chat_prompts?.splice(0, 4)} showPrompts={true} apiCall={doSend} />
              {/* Other content if needed */}
            </div>
          )}
        </div>
        {output && (
          <div className="flex-1 h-full"> {/* This ensures that the div takes full height */}
            <AppOutputPreview key={output} disableIterate={true} run={currentRun} runId={runId} appId={currentApp?.id} output={output} outputType={currentApp.output_type} />
          </div>
        )}
      </div>
    </>

  )

}
export default AppRunIteratePage