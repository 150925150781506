import React, { useState, useEffect } from "react";
import { ShimmerTitle, ShimmerText } from "react-shimmer-effects";
import { useApi } from "../../callApi";
import VoiceRecoder from "../VoiceRecorder";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const TopicExplorer = ({ input, setInputData, setIsGenerateDisabled, outputType }) => {
  const { callApi } = useApi(true);
  const [topics, setTopics] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [facts, setFacts] = useState({});
  const [factsImages, setFactsImages] = useState({});
  const [loadingFacts, setLoadingFacts] = useState({});
  const [loadingTopics, setLoadingTopics] = useState(false);
  const [currentQuery, setCurrentQuery] = useState("");

  useEffect(() => {
    if (topics.length > 0 && selectedTopics.length === 0) {
      const firstTopic = topics[0];
      handleSelectTopic(firstTopic);
    }
  }, [topics]);

  const fetchTopics = async (topic) => {
    if (!topic) return;

    setLoadingFacts({});
    setFacts({});
    setSelectedTopics([]);
    setLoadingTopics(true);
    try {
      const data = await callApi(`/topic-explorer/explore`, {}, null, { query: topic });
      setTopics(data);
      setLoadingTopics(false);
    } catch (error) {
      setLoadingTopics(false);
      console.error('Failed to fetch topics:', error);
    }
  };

  const fetchFactsForTopic = async (topic) => {
    if (!topic) return;

    setLoadingFacts(prev => ({ ...prev, [topic]: true }));
    try {
      const data = await callApi(`/topic-explorer/facts`, {}, null, { query: topic, output_type: outputType });
      const data_images = await callApi(`/topic-explorer/facts-images`, {}, null, { query: topic, output_type: outputType });
      setFacts(prev => ({ ...prev, [topic]: data }));
      setFactsImages(prev => ({ ...prev, [topic]: data_images }));
      //setIsGenerateDisabled(false);
    } catch (error) {
      console.error('Failed to fetch facts for topic:', topic, error);
    } finally {
      setLoadingFacts(prev => ({ ...prev, [topic]: false }));
    }
  };

  const handleSelectTopic = (topic) => {
    if (!selectedTopics.includes(topic)) {
      setSelectedTopics([...selectedTopics, topic]);
      fetchFactsForTopic(topic);
    }
  };

  const handleRemoveTopic = (topic) => {
    setSelectedTopics(selectedTopics.filter(t => t !== topic));
    const updatedFacts = { ...facts };
    delete updatedFacts[topic];
    setFacts(updatedFacts);
  };

  const handleInputChange = (e, updatedValue) => {
    setCurrentQuery(updatedValue || e?.target?.value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      fetchTopics(currentQuery);
    }
  };

  const handleBlur = () => {
    fetchTopics(currentQuery);
  };

  useEffect(() => {
    if (!facts)
      return



    if (Object.keys(facts).length != 0) {

      let content = JSON.stringify(facts)

      // if (Object.keys(factsImages).length != 0) {
      //   let firstopic = Object.keys(factsImages)[0]

      //   let images = factsImages[firstopic].images
      //   content = content + ". Use images below:" + JSON.stringify(images)

      // }


      setInputData((prevData) => ({
        ...prevData,
        ['text']: content
      }));
      setIsGenerateDisabled(false)
    }


  }, [facts, factsImages])

  return (
    <div className={`${input.className} flex flex-col w-full`}>
      <div className="text-left mb-4 p-2 bg-blue-50 border-l-4 border-blue-200 rounded">
        <p className="text-sm font-medium text-blue-800 flex items-center">
          <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
          Search the web for content to enrich generation.
        </p>
      </div>


      <div className="w-full relative my-2">
        <div className="flex items-center border border-gray-300 bg-white rounded-full shadow-sm">
          <VoiceRecoder
            className="flex ml-2 items-center justify-center py-3 px-4 text-sm border-black rounded-full text-black  hover:bg-gray-200 transition duration-300 ease-in-out focus:outline-none"
            onTranscribed={(text) => { handleInputChange(null, text) }}
            compactMode={true}
          />
          <input
            type="text"
            value={currentQuery}
            onKeyDown={handleInputKeyDown}
            onChange={handleInputChange}
            placeholder="Search Web..."
            className="flex-1 p-4 text-lg rounded-full w-full focus:outline-none"
          />
          {loadingTopics && <div className="absolute top-0 right-0 h-full flex items-center pr-3"><div className="spinner"></div></div>}
        </div>
      </div>

      <div className="flex flex-wrap gap-2 my-4">
        {topics.map((topic, index) => (
          <div
            key={index}
            onClick={() => handleSelectTopic(topic)}
            className={`cursor-pointer rounded px-3 py-1 text-xs font-semibold
                        ${selectedTopics.includes(topic) ? 'bg-blue-600 text-white' : 'bg-blue-100 text-blue-800'}
                        hover:bg-blue-500 hover:text-white transition-colors duration-300`}
          >
            {topic}
            {selectedTopics.includes(topic) && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveTopic(topic);
                }}
                className="ml-2 text-white hover:bg-blue-700 rounded-full"
              >
                ×
              </button>
            )}
          </div>
        ))}
      </div>

      {selectedTopics.map((topic) => (
        <div key={topic} className="bg-white p-4 rounded shadow-md my-2">
          {loadingFacts[topic] ? (
            <>
              <ShimmerTitle />
              <ShimmerText line={3} />
            </>
          ) : (
            facts[topic] &&
            <div>
              <h3 className="text-gray-700 font-bold text-md">{topic}</h3>
              <p className="text-gray-700 text-sm" dangerouslySetInnerHTML={{ __html: facts[topic] }}></p>
              <div className="grid grid-cols-4 gap-2 mt-2">
                {factsImages[topic].images.map((image) => (
                  <img src={image.thumbnailUrl} alt="" key={image.id} className="w-full object-cover h-20" />
                ))}
              </div>
            </div>
          )}
        </div>
      ))}

    </div>
  );
};

export default TopicExplorer;
