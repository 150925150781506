import React, { useEffect, useState } from 'react';
import LandingHeader from '../../components/shared/LandingHeader';
import BrandListContainer from './BrandListContainer';

const BrandsListPage = () => {

  return (
    <>

      <LandingHeader />
      <div className="flex justify-center my-4">
        <button
          className="flex items-center text-center justify-center mt-4 bg-black text-white font-medium border-2 border-black py-2 px-4 rounded-md hover:bg-opacity-90 transition-all duration-300 hover:border-yellow-500 hover:shadow"
          type="button"
          onClick={() => { window.location = '/user/brands/editor' }}
        >
          Generate New
        </button>
      </div>
      <BrandListContainer />
    </>

  );
};

export default BrandsListPage;
