import React, { useState } from 'react';
import LandingHeader from '../components/shared/LandingHeader';

const PrivacyPolicyPage = () => {
  const sections = [
    {
      title: "Information Collection",
      content: "At MakeGen.ai, our primary data collection is limited to the personal information necessary for account creation and service delivery. This includes your email address, which we use exclusively to send you reminders and updates related to our services. Our data collection practices are designed with your privacy in mind, ensuring we collect only what is necessary for the specified functions of our application."
    },
    {
      title: "Use of Information",
      content: "The personal information we collect, such as your email address, is used solely for the purpose of providing you with our service, including sending reminders, updates, and important notifications. We are committed to transparently and responsibly using your information, ensuring it is used strictly within the bounds of the services we provide and the preferences you have set."
    },
    {
      title: "Sharing and Disclosure",
      content: "We uphold the highest standards of privacy. Therefore, we do not share, sell, or disclose your personal information to third parties. The only exceptions are where we have your explicit consent, are required by law to comply with legal obligations, need to enforce our policies, or must facilitate a business transfer, in which case you will be duly informed and your consent sought where applicable."
    },
    {
      title: "Data Security",
      content: "We implement robust security measures to protect your information against unauthorized access, alteration, disclosure, or destruction. We understand the importance of safeguarding your personal information and strive to maintain the security of your data. However, while we aim for the highest security standards, it's important to note that no method of transmission over the Internet, or method of electronic storage, is 100% secure."
    },
    {
      title: "Your Rights",
      content: "You have the right to access, update, or request deletion of your personal information at any time. Our platform enables you to review, modify, or delete your data through your account settings. Should you need assistance, or wish to exercise any of your privacy rights beyond what is available through your account settings, please contact us directly."
    },
    {
      title: "Children's Privacy",
      content: "Our services are designed for a general audience and are not intentionally targeted at children under the age of 13. We do not knowingly collect personal information from children without obtaining parental consent in accordance with the Children's Online Privacy Protection Act (COPPA)."
    },
    {
      title: "Changes to Our Privacy Policy",
      content: "We may update our Privacy Policy from time to time to reflect changes in our practices, technology, legal requirements, or other factors. We will notify you of any significant changes to our policy and obtain your consent where required by law, ensuring you always have the most current information on our data practices."
    },
    {
      title: "Contact Us",
      content: "If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at ahmed@behairy.me. We are committed to addressing your privacy and data protection concerns."
    },
  ];


  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (index) => {
    if (openSection === index) {
      return setOpenSection(null);
    }
    setOpenSection(index);
  };

  return (
    <>
      <LandingHeader />

      <div className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="mb-12 text-center">
            <h1 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">Privacy Policy</h1>
            <p className="mt-4 text-lg leading-6 text-gray-500">
              Your privacy is important to us.
            </p>
          </div>
          <dl className="space-y-6 divide-y divide-gray-200">
            {sections.map((section, index) => (
              <div key={index} className={`${openSection === index ? 'bg-gray-100' : 'bg-white'} p-6 rounded-lg`}>
                <dt className="text-lg leading-6 font-medium text-gray-900 cursor-pointer" onClick={() => toggleSection(index)}>
                  {section.title}
                </dt>
                <dd className={`mt-2 ${openSection === index ? 'block' : 'hidden'} text-base text-gray-500`}>
                  {section.content}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicyPage;
