import React, { useEffect, useRef, useState } from 'react';
import Reveal from 'reveal.js';
import 'reveal.js/dist/reveal.css'; // Core styles
import 'reveal.js/dist/theme/white.css'; // Theme (can be changed to another theme)

const SlidesViewer = ({ data }) => {
  const revealRef = useRef(null);
  const [htmlContent, setHtmlContent] = useState('')


  const extractHTML = (text) => {
    const regex = /```html\s+([\s\S]*?)\s+```/;
    const matches = text.match(regex);
    return matches ? matches[1] : null;
  };


  useEffect(() => {
    // data = extractHTML(data['htmlContent'])
    const slidesHtmlContent = data?.slidesHtmlContent?.join(' ')
    setHtmlContent(slidesHtmlContent)
    const revealConfig = data?.revealConfig || {}

    // override: enforce removal of hash and history
    revealConfig.embedded = true
    revealConfig.center = true
    revealConfig.hash = false
    revealConfig.touch = true
    revealConfig.history = false
    revealConfig.slideNumber = true
    revealConfig.respondToHashChanges = false


    // Ensure Reveal.js is initialized after the component mounts
    const deck = new Reveal(revealRef.current, {
      // Configuration options
      ...revealConfig
    });
    deck.initialize();

    // Clean up when the component unmounts
    //return () => deck.destroy();
  }, [data]);

  return (
    <div style={{ 'height': '500px' }} className='reveal' ref={revealRef}>
      {data && (
        <div className="slides" dangerouslySetInnerHTML={{ __html: htmlContent }}>

        </div>
      )}
      {!data && (

        <div className='slides'>
          <section>
            <h1>How to Do Great Work</h1>
            <p>If you collected lists of techniques for doing great work in a lot of different fields, what would the intersection look like? I decided to find out by making it. Partly my goal was to create a guide that could be used by someone working in any field...</p>
          </section>

          <section>
            <h2>Choosing the Right Work</h2>
            <p>The first step is to decide what to work on. The work you choose needs to have three qualities: it has to be something you have a natural aptitude for, a deep interest in, and offers scope to do great work.</p>
          </section>

          <section>
            <h3>Developing Curiosity</h3>
            <p>Curiosity is the best guide in doing great work, leading you to the field, the frontier, the gaps, and driving you to explore them.</p>
          </section>

          <section>
            <h4>Collaborate and Seek the Best Colleagues</h4>
            <p>Working with colleagues who inspire you and push you to your limits can greatly impact the quality of your work and boost your morale.</p>
          </section>

          <section>
            <h5>Managing Morale and Productivity</h5>
            <p>Your morale is a critical component in doing great work. It's important to take care of your physical and mental well-being to maintain high levels of productivity.</p>
          </section>

          <section>
            <h6>Seek Originality and Avoid Affectation</h6>
            <p>Originality is prized in great work. Avoid affectation and intellectual dishonesty to stay true to yourself and your ideas.</p>
          </section>

        </div>

      )}
    </div >


  );
};

export default SlidesViewer;
