import React, { useState, useEffect } from "react";
import { faBolt } from '@fortawesome/free-solid-svg-icons'; // Assuming these are the correct names
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FileUploader from "./FileUploader";
import ColorPicker from "./ColorPicker";
import VoiceRecoder from "./VoiceRecorder";
import ChatComponent from "./viewers/ChatComponent";
import { useApi } from "../callApi";
import TopicExplorer from "./inputs/TopicExplorer";
import CsvDBLoader from "./inputs/CsvDBLoader";
import ToggleSwitch from "./ToggleSwitch";
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const AppInput = ({ app, inputs = [], data = {}, onGenerateClicked, setFeaturedThumbnail, setDisableGeneration }) => {
  const { callApi } = useApi(true);
  const [inputData, setInputData] = useState(data);
  const [appRunning, setAppRunning] = useState(false);
  const [thumbnails, setThumbnails] = useState({});
  const [inputValidity, setInputValidity] = useState({});
  const [isGenerateDisabled, setIsGenerateDisabled] = useState(true);
  const [brands, setBrands] = useState([]);

  const [selectedBrand, setSelectedBrand] = useState();
  const [brandInfo, setBrandInfo] = useState(null);
  const [mode, setMode] = useState(app?.model_mode || 'fast');

  // Helper function to validate URL
  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };
  const handleInputChange = async (e, inputName, inputType, updatedValue) => {
    const newValue = updatedValue || e.target.value;
    setInputData(prevData => ({
      ...prevData,
      [inputName]: newValue
    }));

    if (inputType === 'url') {
      let thumbnailUrl = '';
      // Check if it's a YouTube URL and extract thumbnail
      if (newValue.includes('youtube.com/watch?v=') || newValue.includes('youtu.be/')) {
        const videoIdMatch = newValue.match(/(?:youtube\.com\/(?:[^\/\n\s]+\/\s*[^\/\n\s]+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
        if (videoIdMatch && videoIdMatch[1]) {
          thumbnailUrl = `https://img.youtube.com/vi/${videoIdMatch[1]}/maxresdefault.jpg`;
        }
      } else {
        // For other URLs, call your backend API to get a screenshot
        try {
          const response = await fetch(`/api/thumbnail?url=${encodeURIComponent(newValue)}`);
          if (response.ok) {
            const data = await response.json();
            thumbnailUrl = data.thumbnailUrl; // Adjust according to your API response structure
          }
        } catch (error) {
          //console.error("Failed to fetch thumbnail:", error);
        }
      }

      // Update thumbnails state
      if (thumbnailUrl) {
        setThumbnails(prevThumbnails => ({
          ...prevThumbnails,
          [inputName]: thumbnailUrl
        }));
        setFeaturedThumbnail(thumbnailUrl)
      }
    }
  };

  const handleInputBlur = (inputName, inputType) => {
    if (inputType === 'url') {
      // Validate on blur
      const isValid = isValidUrl(inputData[inputName]);
      setInputValidity(prevValidity => ({
        ...prevValidity,
        [inputName]: isValid
      }));
      if (!isValid) {
        // Optional: Handle further actions like showing a message, etc.
      }
    } else { // default to valid
      setInputValidity(prevValidity => ({
        ...prevValidity,
        [inputName]: true
      }));

    }
  };
  const handleFileChange = (e, inputName) => {
    // Handle file inputs separately
    setInputData(prevData => ({
      ...prevData,
      [inputName]: e
    }));
    setIsGenerateDisabled(false)
  };



  const handleGenerateClick = () => {
    // Here you would validate your inputData
    const isValidInput = true; // Placeholder for actual validation logic
    if (isValidInput) {
      onGenerateClicked(inputData, mode);
      setAppRunning(true);
    }
  };
  const handleBrandChange = async (e) => {
    const brandId = e.target.value;
    setSelectedBrand(brandId);

    if (brandId === 'new') {
      // Code to handle new brand creation
    } else {
      const selected = brands.find(brand => brand.id === brandId);
      setBrandInfo(selected);
      // Fetch additional information about the brand if necessary
    }
  };
  const renderInputs = (customInputs) => {
    let inputList = customInputs;
    if (!customInputs)
      inputList = app?.inputs || inputs;
    return inputList.map((input) => {

      const isInvalid = inputValidity[input.name] === false;

      switch (input.type) {
        case 'file':
          return (
            <FileUploader uploadedFileUrl={inputData[input.name]} setDisableGeneration={setDisableGeneration} input={input} onFileSelect={(e) => handleFileChange(e, input.name)} />
          );
        case 'csv-db-loader':
          return (
            <CsvDBLoader uploadedFileUrl={inputData[input.name]} setIsGenerateDisabled={setIsGenerateDisabled} input={input} onFileSelect={(e) => handleFileChange(e, input.name)} />
          );
        case 'color':
          return (
            <ColorPicker
              input={input}
              key={input.name}
              color={inputData[input.name] || '#ffffff'}
              onColorChange={(color) => {
                handleInputChange({ target: { value: color.hex } }, input.name, input.type);
              }}
            />
          );

        case 'text':
        case 'url':
        case 'number': // Adding number input type
          return (
            <>
              <label className="input-label">{input.label}</label>
              <input
                key={input.name}
                type={input.type}
                value={inputData[input.name] || input.value || ''}
                onChange={(e) => handleInputChange(e, input.name, input.type)}
                onBlur={() => handleInputBlur(input.name, input.type)}
                className={`${isInvalid ? 'border-red-500' : 'border-black'} input input-bordered w-full p-2 max-w-xs border-2  text-black bg-white`}
                placeholder={input.placeholder}
              />
              {thumbnails[input.name] && (
                <img width='40%' key={`${input.name}-thumbnail`} src={thumbnails[input.name]} alt="Thumbnail" className="mt-2" />
              )}
            </>
          );

        case 'select': // Adding select input type
          return (
            <>
              <label className="input-label">{input.label}</label>
              <select
                key={input.name}
                value={inputData[input.name] || ''}

                onChange={(e) => handleInputChange(e, input.name, input.type)}
                className={`${isInvalid ? 'border-red-500' : 'border-black'} select select-bordered w-full p-2 max-w-xs border-2 text-black bg-white`}
              >
                {input.options.map((option) => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
            </>
          );

        case 'textarea': // Adding textarea input type
          return (
            <>
              <label className="input-label">{input.label}</label>
              <textarea
                key={input.name}
                value={inputData[input.name] || input.value || ''}
                onBlur={() => handleInputBlur(input.name, input.type)}
                onChange={(e) => handleInputChange(e, input.name, input.type)}
                className={`${isInvalid ? 'border-red-500' : 'border-black'} textarea textarea-bordered w-full p-2 max-w-xs border-2 text-black bg-white`}
                placeholder={input.placeholder}
              />
            </>
          );
        case 'topic-explorer':
          return (
            <TopicExplorer outputType={app?.output_type} setIsGenerateDisabled={setIsGenerateDisabled} input={input} setInputData={setInputData} inputData={inputData} />

          );



        case 'textarea-with-voice':
          return (
            <div className={`${input.className} flex flex-col w-full`}>
              <div className="flex justify-between sm:text-sm w-full max-width items-center p-2 bg-gray-100 border-b border-gray-200">
                <div className="flex-0 mr-2">
                  <VoiceRecoder
                    onTranscribed={(text) => { handleInputChange({ target: {} }, input.name, input.type, text) }}
                    setDisableGeneration={() => { }}
                    compactMode={true}
                  />
                </div>
                <label className="input-label text-lg flex-1">{input.label}</label>

              </div>
              <textarea
                key={input.name}
                value={inputData[input.name] || ''}
                onBlur={() => handleInputBlur(input.name, input.type)}
                onChange={(e) => handleInputChange(e, input.name, input.type)}
                className={`${isInvalid ? 'border-red-500' : 'border-gray-300'} resize-none w-full p-3 border text-black bg-white`}
                placeholder={input.placeholder}
                rows="6" // Adjust the number of rows as needed
              />
            </div>
          );

        case 'voice': // Adding textarea input type
          return (
            <>
              <label className="input-label">{input.label}</label>
              <VoiceRecoder setDisableGeneration={setDisableGeneration} input={input} onFileSelect={(e) => handleFileChange(e, input.name)} />

            </>
          );
        case 'chat': // Adding textarea input type
          return (
            <div className="col-span-full">
              <label className="input-label">{input.label}</label>
              <ChatComponent setDisableGeneration={setDisableGeneration} input={input} onFileSelect={(e) => handleFileChange(e, input.name)} />

            </div>
          );
        case 'brand':
          return (<>
            <label className="input-label">Brand</label>
            <select
              value={selectedBrand || ''}
              onChange={handleBrandChange}
              className="select select-bordered w-full p-2 max-w-xs border-2 text-black bg-white"
            >
              <option value="">Select a brand</option>
              {brands.map((brand) => (
                <option key={brand.id} value={brand.id}>{brand.name}</option>
              ))}
              <option value="new">+ Create new brand</option>
            </select>

            {brandInfo && (
              <div className="brand-info">
                {/* Display information about the brand */}
                <p>{brandInfo.description}</p>
                {/* Add more details as needed */}
              </div>
            )}
          </>)

        // Add more case statements for other input types as needed.

        default:
          return null; // Or some other placeholder for unsupported types
      }
    });
  };

  const fetchBrands = async () => {
    try {
      const response = await callApi(`/user/brands`);
      const data = await response.json();
      setBrands(data);
    } catch (error) {
      console.error('Failed to fetch brands:', error);
    }
  };

  useEffect(() => {

  }, [isGenerateDisabled])

  useEffect(() => {

    // Check if any input is invalid, then disable the generate button
    if (Object.keys(inputValidity).length === 0)
      return;

    const isInvalid = Object.values(inputValidity).some(validity => validity === false);

    setIsGenerateDisabled(isInvalid);
  }, [inputValidity]);

  useEffect(() => {
    // Fetch the brands when the component mounts


    //fetchBrands();
  }, []);

  return (
    <div className="bg-white rounded-lg p-6 ">
      <div className="space-y-4">
        {app.is_experimental &&
          <div className="text-left mb-4 p-2 bg-red-50 border-l-4 border-red-200 rounded">
            <p className="text-sm font-medium text-red-800 flex items-center">
              <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
              This feature is experimental. You may need to run multiple times to get a result.
            </p>
          </div>}
        <h2 className="text-lg font-semibold">1. Add Content</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

          {renderInputs([app?.inputs[0]])}


        </div>
        {app?.inputs?.length > 1 && < span >
          <h2 className="text-lg font-semibold">2. Choose Settings</h2>

          <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-4">


            {renderInputs(app?.inputs.slice(1))}
          </div>

        </span>}
      </div>
      {
        !appRunning && (<div className="mt-10">
          <ToggleSwitch defaultMode={app?.model_mode} onChange={(mode) => { setMode(mode) }} />

          <div className="flex items-center justify-center">
            <button
              className={`flex items-center text-center justify-center mt-4 ${isGenerateDisabled ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-black text-white hover:bg-opacity-90'} font-medium border-2 ${isGenerateDisabled ? 'border-gray-400' : 'border-black'} py-2 px-4 rounded-md transition-all duration-300 ${!isGenerateDisabled && 'hover:border-yellow-500 hover:shadow'}`}
              onClick={handleGenerateClick}
              disabled={isGenerateDisabled}

            >
              <FontAwesomeIcon icon={faBolt} size={'1x'} color={'#fbbf24'} />
              &nbsp; Generate
            </button>

          </div>
        </div>

        )
      }
    </div >
  );
};

export default AppInput;
