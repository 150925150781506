import React, { useEffect, useState } from "react";
import ShowCaseCard from "./ShowCaseCard"; // Make sure to create this component
import LoadingIndicator from "../components/shared/LoadingIndicator";
import { useApi } from '../callApi';

const ShowCaseList = () => {
  const [showcases, setShowcases] = useState([]);
  const [loading, setLoading] = useState(false);
  const { callApi } = useApi();

  const fetchShowCases = async () => {
    try {
      setLoading(true);
      // Assuming `callApi` is available to make the API call
      const response = await callApi(`/showcases`);
      setShowcases(response);
    } catch (err) {
      // TODO: handle error
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchShowCases();
  }, []);

  return (
    <section className="py-4 overflow-hidden">
      <div className="container mx-auto px-4">
        <div className="bg-white p-6 border rounded-xl shadow">
          <h3 className="text-xl font-semibold text-gray-800 mb-5">Showcase Gallery</h3>
          {showcases.length === 0 && !loading && (
            <div className="text-center my-20">
              <p className="text-lg text-black font-medium mb-4">No Showcases Found</p>
              <p>Start showcasing your work by adding new projects.</p>
            </div>
          )}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {loading ? <LoadingIndicator /> : showcases.map(showcase => (
              <ShowCaseCard key={showcase.id} showcase={showcase} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShowCaseList;
