import React from 'react';
import { formatDistanceToNow } from 'date-fns';
import { useNavigate } from 'react-router-dom';

const ShowCaseCard = ({ showcase }) => {
  const timeAgo = formatDistanceToNow(new Date(showcase.created_at), { addSuffix: true });
  const navigate = useNavigate();

  const viewShowCaseHandler = () => {
    navigate(`/share/${showcase.uuid}`);
  };

  return (
    <div className="relative group border rounded-lg shadow-sm transition-shadow duration-300 ease-in-out overflow-hidden cursor-pointer">
      {/* Interactive thumbnail */}
      <div className="aspect-w-16 aspect-h-9">
        <img
          src={showcase.data.thumbnailUrl} // Replace with your thumbnail image URL
          alt={`Showcase of ${showcase.title || showcase.name}`}
          className="w-full h-full object-cover"
        />
        {/* Text content */}
        <div className="absolute bottom-0 w-full p-4 bg-black bg-opacity-50 text-white transition duration-300">
          <h4 className="text-lg group-hover:hidden font-bold truncate">{showcase.title || showcase.name}</h4>
          {/* App slug - hide on hover */}
          <p className="text-sm group-hover:hidden">{showcase.title}</p>
        </div>
        {/* Action button - show only on hover */}
        <div className="absolute inset-0 flex items-end justify-center p-4 bg-black bg-opacity-0 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
          <button
            className="text-white font-medium rounded-lg text-sm px-5 py-2.5 hover:bg-white hover:text-black transition-colors duration-300"
            onClick={viewShowCaseHandler}
          >
            View Showcase
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShowCaseCard;
