import LandingHeader from "../components/shared/LandingHeader";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import MainAppGeneratorContainer from "../components/MainAppGeneratorContainer";

const AppGeneratorPage = ({ }) => {
  const { appId, appSlug } = useParams(); // Retrieve the `runId` parameter from the URL


  return (
    <>

      <LandingHeader />
      {appId && <MainAppGeneratorContainer appId={appId} appSlug={appSlug} />}


    </>
  )

}
export default AppGeneratorPage