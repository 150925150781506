import React, { useContext, useEffect, useState } from 'react';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import SubscriptionPage from './pages/SubscriptionPage';
import { useApi } from './callApi';
import LandingHeader from './components/shared/LandingHeader';
import LoadingIndicator from './components/shared/LoadingIndicator';
import { useAuth0 } from "@auth0/auth0-react";

// Subscription context
export const SubscriptionContext = React.createContext(null);

export const useSubscription = () => useContext(SubscriptionContext);

export const SubscriptionProvider = ({ children }) => {
  const [subscription, setSubscription] = useState({ status: 'loading', trialEndDate: null });
  const { callApi } = useApi();
  const { isAuthenticated, isLoading } = useAuth0();



  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      try {
        const response = await callApi('/subscription/details', 'GET');

        const trialEnd = new Date(response.trialEndDate * 1000); // Assuming trialEndDate is a Unix timestamp
        const today = new Date();
        const difference = trialEnd - today;
        const daysLeft = Math.ceil(difference / (1000 * 60 * 60 * 24));
        setSubscription({
          subscription_id: response.id,
          id: response.id,
          status: daysLeft > 0 ? response.status : 'ended',
          trialEndDate: response.trialEndDate,
          daysLeft: daysLeft
        });
      } catch (error) {
        console.error('Error fetching subscription details:', error);
        //  No subscription
        setSubscription({ status: 'new', trialEndDate: null });
      }
    };

    if (!isAuthenticated || isLoading) {
      console.log("Waiting for authentication to complete before fetching subscription.");
      return;
    }

    fetchSubscriptionStatus();
  }, [isAuthenticated]);

  return (
    <SubscriptionContext.Provider value={subscription}>
      {children}
    </SubscriptionContext.Provider>
  );
};

// Protected route component that checks both authentication and subscription status
export const ProtectedRoute = ({ children }) => {
  const subscription = useSubscription();


  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  // Handle authentication loading state
  if (isLoading) {
    return <div><LandingHeader /><LoadingIndicator /></div>;
  }

  // Redirect to login if not authenticated
  if (!isAuthenticated) {
    loginWithRedirect();
    return <div><LandingHeader /><LoadingIndicator /></div>;
  }

  // Existing subscription loading logic
  if (subscription.status === 'loading') {
    return <div><LandingHeader /><LoadingIndicator /></div>;
  }

  const ComponentWithAuth = withAuthenticationRequired(
    () => {
      return children
      // if (subscription)
      //   if (subscription.status !== 'active' && subscription.status !== 'trialing') {

      //     // Redirect or show a message based on subscription status
      //     return <SubscriptionPage subscription={subscription} />
      //   } else {
      //     return children

      //   }
    },
    {
      onRedirecting: () => <div> <LandingHeader /><LoadingIndicator /></div > // Provide a better loading component as needed
    }
  );

  return <ComponentWithAuth />;
};

export default ProtectedRoute;
