import { useApi } from '../callApi';
import React, { useEffect, useState } from "react";
import AppRunCard from "./AppRunCard";
import LoadingIndicator from './shared/LoadingIndicator';


const AppRunListContainer = ({ }) => {
  const { callApi } = useApi();
  const [runs, setRuns] = useState([])
  const [loading, setLoading] = useState(false);

  const fetch = async () => {
    try {
      setLoading(true);
      const response = await callApi(`/user/runs`);
      setRuns(response);
      setLoading(false);

    } catch (err) {
      //@TODO: handle error
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetch();
  }, []);

  return (
    <>

      <section className="py-4 overflow-hidden">
        <div className="container mx-auto px-4">
          <div className="bg-white p-6 border rounded-xl shadow">
            <h3 className="text-xl font-semibold text-gray-800 mb-5">My Work</h3>
            {runs.length == 0 && !loading && (


              <div className="flex flex-col justify-center items-center my-20">
                <p className="text-lg text-black font-medium mb-4">Nothing Found</p>
                <p className="text-md text-gray-600">It looks like you haven't generated any applications yet. Click the "Generate New" button to get started!</p>
              </div>
            )}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {loading && <LoadingIndicator />}

              {runs.map((run) => (
                <AppRunCard key={run.id} run={run} />
              ))}

            </div>
          </div>
        </div>
      </section>



    </>
  )

}
export default AppRunListContainer