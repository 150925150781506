import React, { useState, useEffect, useRef } from 'react';
import { ForceGraph2D } from 'react-force-graph';
import alasql from 'alasql';
import ChatComponent from './ChatComponent';
import { useApi } from '../../callApi';
import LinkedinCharts from './LinkedinCharts';
import LoadingIndicator from '../shared/LoadingIndicator';

const LinkedinDashboard = ({ data }) => {
  const tableName = data.tableName
  const { callApi } = useApi(true);

  const [graphData, setGraphData] = useState({ nodes: [], links: [] });
  const [loading, setLoading] = useState(true);
  const [queryResult, setQueryResult] = useState([]);
  const [customQuery, setCustomQuery] = useState('');
  const [activeTab, setActiveTab] = useState('charts'); // 'graph', 'list'

  const forceGraphRef = useRef();
  const [initialMessages, setInitialMessages] = useState([])


  // Define the tabs
  const tabs = [
    { id: 'charts', title: 'Chart View', content: (data) => <LinkedinCharts tableName={'importedCSV'} data={data} /> },

    { id: 'graph', title: 'Graph View', content: (data) => <ForceGraphContent data={data} /> },
    { id: 'list', title: 'List View', content: (data) => <ListViewContent data={data} /> },

    // Add more tabs if needed
  ];

  const executeCustomQuery = async (query = customQuery, updateGraph = false) => {
    setLoading(true)
    try {
      const result = await alasql.promise(query);
      setQueryResult(result);
      transformDataToGraph(result, "aa@aa.coms", updateGraph); // Transform the result into graph data immediately after querying
      setLoading(false)
      return result

    } catch (error) {
      console.error('Error executing custom query:', error);
      setQueryResult([]);
      setLoading(false)

    }
  };

  // This function converts the query result into a format suitable for react-force-graph
  const transformDataToGraph = (data, mainUserEmail, updateGraph) => {
    if (!updateGraph)
      return
    const nodes = [{ id: 'Main User', group: 'User' }]; // Start with the main user
    const links = [];
    const nodeMap = new Map();
    nodeMap.set('Main User', true); // Add the main user to the node map

    data.forEach((row) => {
      const personName = `${row['First Name']} ${row['Last Name']}`;
      const companyName = row['Company'];
      const email = row['Email Address'];

      // Add person node if not already present
      if (!nodeMap.has(personName)) {
        nodes.push({ id: personName, label: personName, group: 'Person' });
        nodeMap.set(personName, true);

        // Connect each person directly to the main user
        if (email !== mainUserEmail) { // Optionally, check to prevent connecting the person node to the main user based on email
          links.push({ source: 'Main User', target: personName, value: 1 });
        }
      }

      // Add company node if not already present and link it to the person
      if (companyName && !nodeMap.has(companyName)) {
        nodes.push({ id: companyName, label: companyName, group: 'Company' });
        nodeMap.set(companyName, true);
      }
      if (companyName) {
        links.push({ source: personName, target: companyName, value: 1 });
      }
    });

    setGraphData({ nodes, links });
  };
  useEffect(() => {
    executeCustomQuery(`SELECT * FROM ${tableName}`, true);
    doSend('get top 5 companies', false)
  }, [data]);


  const doSend = async (messageContent, updateGraph = true) => {

    setLoading(true)
    let data = {}
    const response = await callApi('/chat_sql?message=' + messageContent, {
      method: 'POST',
    }, {
      message: messageContent, output: 'output', appSlug: ''
    });
    // each repsonse should have {output, message}
    // here check if response has output // if yes execute them
    // then send them back to the backend
    //setOutput(response.output)
    const result = await executeCustomQuery(response.output[0], updateGraph)

    // get result and get commentary here.
    const commentary = await getCommentary(messageContent, result)
    setLoading(false)
    setInitialMessages([{ type: 'ai', content: commentary?.message }])

    return commentary
  }

  const getCommentary = async (messageContent, result) => {

    const response = await callApi('/chat_sql_commentary', {
      method: 'POST',
    }, {
      message: messageContent,
      sql_result: result?.splice(0, 10) || []
    });
    return response

  }

  // Content components for each tab
  const ForceGraphContent = ({ data }) => (
    <ForceGraph2D
      graphData={data}
      ref={forceGraphRef}

      nodeAutoColorBy="group"
      nodeLabel="label"
      width={document.getElementsByClassName('force-graph-container')[0]?.clientWidth}
      height={document.getElementsByClassName('force-graph-container')[0]?.clientHeight}
      enableZoomPanInteraction={true}
      enableNodeDrag={true}
    />
  );

  const ListViewContent = ({ data }) => (
    <div className="h-full overflow-auto">
      <div className="p-2">
        <h3 className="text-lg font-semibold">Connections ({data.nodes.length})</h3>
        {data.nodes.map((node, index) => (
          <div key={node.id} className="flex items-center p-4 my-2 shadow bg-white rounded-lg">
            <div className="flex-grow">
              <p className="font-bold">{node.label}</p>
              <p className="text-sm text-gray-600">{node.group}</p>
            </div>
            <div>
              <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-blue-600 bg-blue-200 last:mr-0 mr-1">
                #{index + 1}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );


  useEffect(() => {
    if (activeTab === 'graph' && forceGraphRef.current) {
      forceGraphRef.current.centerAt(1200, 800, 1000); // Center the graph
      // forceGraphRef.current.zoomToFit(400, 100)// Fit graph to view with padding
    }
  }, [activeTab]);





  return (
    <div className="flex flex-col md:flex-row">
      {/* Chat Component - Full width on mobile, side content on larger screens */}
      <div className="w-full md:w-1/2 p-4 md:border-r">
        {initialMessages.length == 0 &&
          <div className="flex justify-center items-center mb-4">
            <h3 className='text-center mx-auto'>Loading..</h3>

            <LoadingIndicator />

          </div>

        }
        {initialMessages.length > 0 && <ChatComponent enableUpload={false} initialMessages={initialMessages} title="Ask me about your network.." apiCall={doSend} />}
      </div>

      {/* Data Visualization Components - Full width on mobile, side content on larger screens */}
      <div className="w-full md:w-1/2">
        <div className="flex justify-center mb-4">
          {tabs.map(tab => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`px-4 py-2 focus:outline-none ${activeTab === tab.id ? 'text-blue-500 border-b-2 font-medium border-blue-500' : 'text-gray-500'}`}
            >
              {tab.title}
            </button>
          ))}
        </div>

        {/* Content based on active tab */}
        <div className="overflow-auto">
          {loading ? (
            <p>Loading...</p>
          ) : (
            tabs.find(tab => tab.id === activeTab)?.content(graphData)
          )}
        </div>
      </div>
    </div>
  );
};

export default LinkedinDashboard;
