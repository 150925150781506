import React, { useState } from 'react';
import LandingHeader from '../components/shared/LandingHeader';
import MainAppGeneratorContainer from "../components/MainAppGeneratorContainer";
import CSVLoader from '../components/inputs/CsvLoader';



const Demo = ({ }) => {
  //web - to - article / 32

  // Function to toggle FAQ answer visibility
  const [openFAQ, setOpenFAQ] = useState(null);

  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      return setOpenFAQ(null);
    }
    setOpenFAQ(index);
  };

  return (
    <>
      <LandingHeader />

      <CSVLoader />
      {/* <div class="w-1/3 bg-blue-500 h-32">
          <MainAppGeneratorContainer appId={32} appSlug={'web-to-article'} />

        </div>
        <div class="w-1/3 bg-red-500 h-32">
          <MainAppGeneratorContainer appId={26} appSlug={'website-to-website'} />

        </div> */}




    </>

  );
};

export default Demo;
