import React from 'react';
import { formatDistanceToNow } from 'date-fns';
import { useNavigate } from 'react-router-dom';

const BrandCard = ({ brand }) => {
  const timeAgo = formatDistanceToNow(new Date(brand.created_at), { addSuffix: true });
  const navigate = useNavigate();

  const viewResultsHandler = () => {
    navigate(`/user/brands/${brand.uuid}`);
  };

  // Thumbnail style
  const thumbnailStyle = {
    width: '50px', // Set your desired thumbnail width
    height: '50px', // Set your desired thumbnail height
    objectFit: 'cover', // This ensures the image covers the area, change as needed
    borderRadius: '50%', // Optional: makes the image round
    marginRight: '1rem', // Add some space between the thumbnail and the text
  };

  return (
    <div className="border bg-white rounded-lg shadow-sm hover:shadow transition-shadow duration-200 ease-in-out">
      <div className="p-4 flex items-center"> {/* Adjusted for flex layout */}
        {/* Conditionally render the thumbnail image if it exists */}
        {brand?.data?.logo_url && (
          <img
            src={brand?.data?.logo_url}
            alt={`${brand.title} thumbnail`}
            style={thumbnailStyle}
          />
        )}
        <div>
          <h4 className="text-lg font-medium text-gray-900">{brand.data?.title}</h4>
          <div className="text-sm text-gray-500">
            <h2 className="font-medium text-2xl">{brand.data?.name}</h2>
            <p>{brand?.data.description}</p>
          </div>
        </div>
      </div>
      <div className="px-4 py-3 bg-gray-50 rounded-b-lg flex justify-between items-center">
        <p className='text-sm text-gray-500'>Created {timeAgo}</p>

        {/* <span className="text-sm text-gray-500">{brand.data?.name}</span> */}
        <button
          className="text-black bg-white border-2 border-black font-medium rounded-lg text-sm px-5 py-2.5 transition-all duration-300 hover:border-yellow-500 hover:shadow"
          onClick={viewResultsHandler}
        >
          View Brand
        </button>

      </div>
    </div>
  );
};

export default BrandCard;
