import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import Logo from './Logo';
import TrialAnnouncementBar from './TrialAnnouncementBar';

const LandingHeader = ({ showNavMenu = true, showLogo = true }) => {
  const { isAuthenticated, loginWithRedirect, logout, isLoading } = useAuth0();

  const handleLogout = () => {
    const webBaseUrl = process.env.REACT_APP_WEB_BASE_URL;
    logout({ logoutParams: { returnTo: webBaseUrl } });

    // logout({ returnTo: window.location.origin });
  };

  if (isLoading)
    return

  const handleSignUp = () => {
    loginWithRedirect();
  };

  return (
    <>
      <nav className="bg-black text-white">
        <div className="container mx-auto flex items-center justify-between py-4 px-4 md:px-6">

          {showLogo && (<Logo />)}




          {isAuthenticated && showNavMenu && (
            <div className="flex items-center space-x-4">

              <a href="/dashboard" className="text-white hover:text-gray-300">Apps</a>
              <a href="/user/subscription" className="text-white hover:text-gray-300">Billing</a>

              {/* <a href="/user/brands" className="text-white hover:text-gray-300">Brands</a> */}
              <a href="/user/runs" className="text-white hover:text-gray-300">My Work</a>
              <button onClick={handleLogout} className="py-2 px-4 border-2 border-white text-white hover:bg-white hover:text-black rounded transition duration-300">
                Logout
              </button>
            </div>
          )}

          {!isAuthenticated && showNavMenu && (
            <div className="flex items-center space-x-4">
              <a href="/dashboard" className="text-white hover:text-gray-300">Apps</a>
              <a href="/pricing" className="text-white hover:text-gray-300">Pricing</a>
              <a href="/faq" className="text-white hover:text-gray-300">FAQs</a>
              {/* Add a Sign Up button for non-authenticated users */}
              <button onClick={handleSignUp} className="py-2 px-4 border-2 border-white text-white hover:bg-white hover:text-black rounded transition duration-300">
                Sign Up
              </button>
            </div>
          )}

        </div>
        <TrialAnnouncementBar isAuthenticated={isAuthenticated} />

      </nav>
    </>
  );
}

export default LandingHeader;
