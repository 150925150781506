import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import MainAppGeneratorContainer from "../components/MainAppGeneratorContainer";
import AppListContainer from "../components/AppListContainer";
import LandingHeader from "../components/shared/LandingHeader";
import tracker from "../tracker";
import AppTypeNavbar from "../components/shared/AppTypeNavbar";
import AppLanding from "./AppLanding";
import ShowCaseList from "./ShowCaseList";
import WelcomeCard from "./WelcomeCard";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faL } from '@fortawesome/free-solid-svg-icons';
import Modal from "../components/shared/Modal";

export default function DashboardPage() {
  const { user, isAuthenticated } = useAuth0();
  const [currentAppType, setCurrentAppType] = useState(null);
  const [currentApp, setCurrentApp] = useState(null);
  const [isSoonModalOpen, setIsSoonModalOpen] = useState(false)
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  useEffect(() => {
    if (isAuthenticated && user) {
      tracker.identifyUser(user);
    }
  }, [user, isAuthenticated]);

  const onCardSelect = (card) => {
    if (card.soon) {
      setIsSoonModalOpen(true)
      return
    }
    if (card.action === '*') {
      const element = document.getElementById('app-list');
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
      return;
    }
    setCurrentApp({ app_slug: card.action + '-to-' + currentAppType.action });
  };

  const handleSelectAppType = (app) => {
    if (app.soon) {
      setIsSoonModalOpen(true)
      return
    }

    window.location = '/apps/' + app.action + '/' + app.title


    // window.history.pushState({ appType: type }, '', window.location.pathname);
    // setCurrentAppType(type);
    // setCurrentApp(null);
    // setIsMenuVisible(false);
    // Save to localStorage
    // localStorage.setItem('appState', JSON.stringify({ currentAppType: type, currentApp: null }));
  };

  const handleSelectApp = (app) => {
    window.history.pushState({ app: app }, '', window.location.pathname);
    setCurrentApp(app);
    // Update and save the new state
    // localStorage.setItem('appState', JSON.stringify({ ...JSON.parse(localStorage.getItem('appState') || '{}'), currentApp: app }));
  };


  // useEffect(() => {

  //   const savedState = JSON.parse(localStorage.getItem('appState'));
  //   if (savedState) {
  //     if (savedState.currentAppType) {
  //       setCurrentAppType(savedState.currentAppType);
  //     }
  //     if (savedState.currentApp) {
  //       setCurrentApp(savedState.currentApp);
  //     }

  //   }
  //   // Listen for popstate event to handle browser navigation
  //   const handlePopState = (event) => {
  //     if (event.state) {
  //       setCurrentAppType(event.state.appType);
  //       setCurrentApp(event.state.app);
  //     } else {
  //       setCurrentAppType(null);
  //       setCurrentApp(null);
  //     }
  //   };

  //   window.addEventListener('popstate', handlePopState);
  //   return () => {
  //     window.removeEventListener('popstate', handlePopState);
  //   };
  // }, []);

  return (
    <>
      <LandingHeader />

      {/* <div className="block md:hidden">
        <button
          className="w-full text-center py-2 bg-white"
          onClick={() => setIsMenuVisible(!isMenuVisible)}
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />

          Back to Apps
        </button>
      </div> */}

      <div className="flex flex-col md:flex-row">
        {/* Sidebar Menu for mobile */}
        {/* <div
          className={`${isMenuVisible ? "block" : "hidden"
            } md:block w-full md:w-auto`}
        >
          <AppTypeNavbar opened={isMenuVisible} onSelectAppType={handleSelectAppType} />
        </div> */}

        {/* Main Content */}
        {!currentApp && (
          <div className="flex-grow">
            {!currentAppType ? (
              <>
                <WelcomeCard onSelectAppType={handleSelectAppType} />
                <AppListContainer
                  onAppSelect={handleSelectApp}
                  key={'currentAppType'}
                  title={`Featured Apps`}
                  featured={true}
                  iconMode={'input'}
                />
                <ShowCaseList />

              </>
            ) : (
              <>
                {/* <AppLanding onCardSelect={onCardSelect} currentApp={currentAppType} /> */}
                <span id="app-list">
                  <AppListContainer
                    onAppSelect={handleSelectApp}
                    key={currentAppType.action}
                    title={`${currentAppType.title} Apps`}
                    outputType={currentAppType.action}
                    categoryType={currentAppType.category}
                    useCaseType={currentAppType.usecase}
                    iconMode={'input'}
                  />
                </span>


              </>
            )}
          </div>
        )}

        {currentApp && (
          <MainAppGeneratorContainer
            demo={true}
            onBackButton={() => window.history.back()}
            key={currentApp.app_slug}
            appId={currentApp?.id}
            appSlug={currentApp.app_slug}
          />
        )}
      </div>
      <Modal title={'Coming Soon !'} isOpen={isSoonModalOpen} onClose={() => setIsSoonModalOpen(false)} >
        <h1>Almost there ..this feature is coming soon as we train the model..</h1>

      </Modal>
    </>
  );
}
