import LandingHeader from "../components/shared/LandingHeader";
import { useParams } from "react-router-dom";
import { useApi } from '../callApi';
import React, { useEffect, useState } from "react";
import AppOutputPreview from "../components/AppOutputPreview";
import LoadingIndicator from '../components/shared/LoadingIndicator';
import ChatComponent from "../components/viewers/ChatComponent";
import { createClient } from '@supabase/supabase-js'

const supabaseUrl = 'https://qtwobmfyhbevtzpgzxgu.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InF0d29ibWZ5aGJldnR6cGd6eGd1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDk0NTM4NzcsImV4cCI6MjAyNTAyOTg3N30.kVyCEJQ1RQMi2aeweXp7FTnMkwF5RmX-WawbXdRHxZ0';
const supabase = createClient(supabaseUrl, supabaseAnonKey);


const AppMakeGenPage = ({ }) => {
  const { runId } = useParams(); // Retrieve the `runId` parameter from the URL
  const { callApi } = useApi();
  const [output, setOutput] = useState();
  const [currentApp, setCurrentApp] = useState()
  const [currentRun, setCurrentRun] = useState()

  const subscription = supabase
    .channel('schema-db-changes')
    .on(
      'postgres_changes',
      {
        event: 'UPDATE',
        schema: 'public',
        table: 'users_apps_runs',
        filter: `uuid=eq.${runId}`,
      },
      (payload) => {

        setOutput(payload?.new?.data)
      }
    )
    .subscribe()

  const [loading, setLoading] = useState(false);

  const fetch = async () => {
    try {
      setLoading(true);
      const response = await callApi(`/user/runs/` + runId);
      setOutput(response.data);
      setCurrentApp(response.apps)
      setCurrentRun(response)

      setLoading(false);

    } catch (err) {

    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    fetch();
  }, []);

  return (
    <>
      <LandingHeader />
      {loading && <LoadingIndicator />}

      <div className="flex flex-col md:flex-row items-start gap-4">
        <div className="flex-1">
          {currentApp && currentRun && (
            <div className="h-full p-4 border-2 border-gray-300   bg-white"> {/* Adjust styles as needed */}
              <ChatComponent isStreaming={true} title="Let's iterate to make it perfect .." chatPrompts={currentApp?.chat_prompts?.splice(0, 4)} showPrompts={true} streamingUrl={'agent_stream'} />
              {/* Other content if needed */}
            </div>
          )}
        </div>
        {output && (
          <div className="flex-1 h-full"> {/* This ensures that the div takes full height */}
            <AppOutputPreview key={output} disableIterate={true} run={currentRun} runId={runId} appId={currentApp?.id} output={output} outputType={currentApp.output_type} />
          </div>
        )}
      </div>
    </>

  )

}
export default AppMakeGenPage