import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt } from '@fortawesome/free-solid-svg-icons';

const ToggleSwitch = ({ defaultMode = 'fast', onChange }) => {
  const [isFastMode, setIsFastMode] = useState(defaultMode == 'fast' ? true : false);

  const toggleMode = () => {
    setIsFastMode(!isFastMode);
    onChange(!isFastMode ? 'fast' : 'quality');
  };

  return (
    <div className="flex items-center justify-center">
      <div className="flex items-center">
        {/* Label for Fast Mode */}
        <span className={`cursor-pointer ${isFastMode ? 'text-yellow-500' : 'text-gray-400'}`} onClick={toggleMode}>
          <FontAwesomeIcon icon={faBolt} size={'1x'} />
          &nbsp; Fast Mode
        </span>

        {/* Toggle switch */}
        <div className="w-14 h-8 flex items-center bg-gray-300 rounded-full mx-3 p-1" onClick={toggleMode}>
          {/* Switch */}
          <div className={`bg-white w-6 h-6 rounded-full shadow-md transform transition-transform duration-300 ${isFastMode ? 'translate-x-6' : 'translate-x-0'}`}></div>
        </div>

        {/* Label for Best Quality Mode */}
        <span className={`cursor-pointer ${!isFastMode ? 'text-yellow-500' : 'text-gray-400'}`} onClick={toggleMode}>
          Best Quality
        </span>
      </div>
    </div>
  );
};

export default ToggleSwitch;
