import React, { useState } from 'react';
import alasql from 'alasql';
import { ForceGraph2D } from 'react-force-graph';

function CSVLoader() {
  const [queryResult, setQueryResult] = useState([]);
  const [graphData, setGraphData] = useState({ nodes: [], links: [] });
  const [tableName, setTableName] = useState('importedCSV');
  const [customQuery, setCustomQuery] = useState('');

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = function (event) {
      const csvContent = event.target.result;
      alasql.promise(`DROP TABLE IF EXISTS ${tableName}; CREATE TABLE ${tableName}`)
        .then(() => {
          alasql.promise(`SELECT * INTO ${tableName} FROM CSV(?, {headers: true})`, [csvContent])
            .then(() => {
              executeCustomQuery(`SELECT * FROM ${tableName}`); // Automatically display all data after loading
            })
            .catch(error => console.error('Error loading CSV data into AlaSQL table:', error));
        })
        .catch(error => console.error('Error creating table in AlaSQL:', error));
    };
    reader.readAsText(file);
  };

  const executeCustomQuery = async (query = customQuery) => {
    try {
      const result = await alasql.promise(query);
      setQueryResult(result);
      transformDataToGraph(result, "aa@aa.coms"); // Transform the result into graph data immediately after querying
    } catch (error) {
      console.error('Error executing custom query:', error);
      setQueryResult([]);
    }
  };

  // This function converts the query result into a format suitable for react-force-graph
  const transformDataToGraph = (data, mainUserEmail) => {
    const nodes = [{ id: 'Main User', group: 'User' }]; // Start with the main user
    const links = [];
    const nodeMap = new Map();
    nodeMap.set('Main User', true); // Add the main user to the node map

    data.forEach((row) => {
      const personName = `${row['First Name']} ${row['Last Name']}`;
      const companyName = row['Company'];
      const email = row['Email Address'];

      // Add person node if not already present
      if (!nodeMap.has(personName)) {
        nodes.push({ id: personName, label: personName, group: 'Person' });
        nodeMap.set(personName, true);

        // Connect each person directly to the main user
        if (email !== mainUserEmail) { // Optionally, check to prevent connecting the person node to the main user based on email
          links.push({ source: 'Main User', target: personName, value: 1 });
        }
      }

      // Add company node if not already present and link it to the person
      if (companyName && !nodeMap.has(companyName)) {
        nodes.push({ id: companyName, label: companyName, group: 'Company' });
        nodeMap.set(companyName, true);
      }
      if (companyName) {
        links.push({ source: personName, target: companyName, value: 1 });
      }
    });

    setGraphData({ nodes, links });
  };

  return (

    <div>
      <input type="file" accept=".csv" onChange={handleFileChange} />
      <textarea
        value={customQuery}
        onChange={(e) => setCustomQuery(e.target.value)}
        placeholder="Enter your SQL query here"
      />
      <button onClick={() => executeCustomQuery()}>Execute Query</button>
      <h3>Query Results:</h3>
      <pre>{JSON.stringify(queryResult, null, 2)}</pre>
      {/* <h3>Graph Data:</h3>
      <pre>{JSON.stringify(graphData, null, 2)}</pre> */}
      <ForceGraph2D
        graphData={graphData}
        nodeAutoColorBy="group"
        nodeLabel={'label'}
        // onNodeClick={handleNodeClick}
        // nodeCanvasObject={nodeCanvasObject}
        // linkCanvasObject={linkCanvasObject}
        enableZoomPanInteraction={true}
        enableNodeDrag={true}
      />
    </div>
  );
}

export default CSVLoader;
