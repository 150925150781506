import React, { useState } from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const WelcomeCard = ({ onSelectAppType }) => {
  const data = [
    { 'category': 'digital-content', output_type: 'website', title: "Microsite", action: "website", icon: "website.png", description: "Create compact, focused websites" },
    { 'category': 'digital-content', output_type: 'newsletter', title: "Newsletter", action: "newsletter", icon: "newsletter.png", description: "Generate newsletters for your audience" },
    { 'category': 'digital-content', output_type: 'article', title: "Article", action: "article", icon: "article.png", description: "Craft engaging, informative articles" },
    // { 'category': 'digital-content', title: "Video", action: "reel", icon: "video.png", description: "Produce captivating video content" },
    { 'category': 'digital-content', output_type: 'podcast', title: "Podcast", action: "podcast", icon: "podcast.png", description: "Launch compelling audio podcasts" },
    { 'category': 'interactive-content', output_type: 'chatbot', title: "Chatbot", hiddenBlank: true, hiddenAI: true, action: "chatbot", icon: "chatbot.png", description: "Build interactive, AI-driven chatbots" },
    { 'category': 'interactive-content', output_type: 'timeline', title: "Timeline", action: "timeline", icon: "timeline.png", description: "Design chronological or thematic timelines" },
    { 'category': 'interactive-content', output_type: 'slides', title: "Slides", action: "slides", icon: "slides.png", description: "Create dynamic presentation slides" },
    { 'category': 'interactive-content', output_type: 'quiz', title: "Quiz", action: "questions", icon: "questions.png", description: "Generate interactive quizzes for engagement" },
    { 'category': 'data-visualization', output_type: 'dashboard', title: "Dashboard", action: "dashboard", icon: "dashboard.png", description: "Build data-rich, interactive dashboards" },
    { 'category': 'data-visualization', output_type: 'chart', title: "Flowchart", action: "chart", icon: "flow-chart.png", description: "Design clear, informative flowcharts" },
    { 'category': 'data-visualization', output_type: 'graph', title: "Tree Graph", action: "graph", icon: "graph.png", description: "Create hierarchical tree graph visuals" },

  ]

  const [apps, setApps] = useState([])

  const handleSelect = (app) => {
    if (app.doCallback) {
      delete app.category

      onSelectAppType(app)

    }

    const result = data.filter(contentType => contentType.category == app.category);
    setApps(result)


  }

  const ContentCard = ({ title, type, action, description, doCallback, icon, onClick }) => (
    <div onClick={() => { onClick({ title: title, action: action, doCallback: doCallback, description: description, category: type, output_icon: icon, icon: icon }) }} className=" cursor-pointer border border-gray-300 bg-white hover:bg-gray-50 transition duration-300 p-4 rounded-lg shadow-sm flex items-center space-x-4">
      <div className="flex-shrink-0">
        <img src={`/assets/images/${icon}`} alt={`${title} icon`} className="h-12 w-12" />
      </div>
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
        <p className="text-gray-500 text-sm">{description}</p>
      </div>
    </div>
  );

  return (
    <section className="py-4 overflow-hidden">
      <div className="container mx-auto px-4">
        <div className="bg-white p-6 border rounded-xl shadow space-y-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Welcome to MakeGen AI!</h2>
          <p className="text-gray-600 mb-4">
            Your journey to impactful content creation starts here. Craft articles, stunning landing pages, interactive quizzes, timelines, and insightful data dashboards with ease.
          </p>
          {apps?.length > 0 && <button onClick={() => setApps([])} className="flex items-center font-semibold text-gray-800 hover:text-gray-600 mb-4 md:mb-0">
            <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
            Back to Categories
          </button>}
          {apps?.length == 0 && <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <ContentCard
              title="Digital Content"
              onClick={handleSelect}
              type="digital-content"
              description="Engage your audience with beautifully crafted articles and landing pages designed to convert."
              icon="digital-content.png" // Replace with your icon path
            />
            <ContentCard
              onClick={handleSelect}
              type="interactive-content"
              title="Interactive Experiences"
              description="Create quizzes, timelines, and more to interactively engage with your users."
              icon="interaction.png" // Replace with your icon path
            />
            <ContentCard
              title="Data Visualization"
              onClick={handleSelect}
              type="data-visualization"
              description="Turn complex data into insightful, easy-to-understand visualizations."
              icon="data-visualization.png" // Replace with your icon path
            />
          </div>}
          <div className='grid grid-cols-1 md:grid-cols-3 gap-6'>
            {apps.map((app) => (
              <ContentCard
                action={app.action}
                doCallback={true}
                title={app.title}
                onClick={handleSelect}
                type={app.category}
                description={app.description}
                icon={`${app.output_icon || app.icon} `} // Replace with your icon path
              />
            ))}
          </div>

          {/* <button className="mt-4 bg-black text-white px-6 py-3 rounded shadow hover:bg-gray-700 transition duration-300">
            Get Started
          </button> */}
        </div>
      </div>
    </section>
  );
};

export default WelcomeCard;
