import React, { useEffect, useState } from 'react';
import { useApi } from '../callApi';
import AppCard from '../components/AppCard';
import LoadingIndicator from './shared/LoadingIndicator';

const AppListContainer = ({ outputType, useCaseType, categoryType, featured, iconMode, onAppSelect, title }) => {
  const [apps, setApps] = useState([]);
  const [loading, setLoading] = useState(false);

  const { callApi } = useApi(true);

  useEffect(() => {
    const fetchApps = async () => {
      try {
        setLoading(true)

        let params = {}
        if (outputType) params['output'] = outputType;
        if (useCaseType) params['usecase'] = useCaseType;
        if (categoryType) params['category'] = categoryType;
        if (featured) params['featured'] = true;

        const data = await callApi(`/apps`, {}, null, params);
        setApps(data);
        setLoading(false)

      } catch (error) {
        setLoading(false)
        console.error(error);
      }
    };

    fetchApps();
  }, []);

  const handleTryNow = (app) => {
    // Call the onAppSelect callback with the selected app
    // if (onAppSelect) onAppSelect(app);
    // else
    window.location = '/apps/generator/' + app.app_slug + '/' + app.id
  };

  return (


    <section className="py-4 overflow-hidden">
      <div className="container mx-auto px-4">
        <div className="bg-white p-6 border rounded-xl shadow">
          <h3 className="text-xl font-semibold text-gray-800 mb-5 capitalize">{title || '100+ Generative AI tools'}</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">

            {loading && <LoadingIndicator />}


            {apps?.map((app) => (
              <AppCard key={app.id} app={app} onTryNow={handleTryNow} iconMode={iconMode} />
            ))}


          </div>
        </div>
      </div>
    </section>
  );
};

export default AppListContainer;
