import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faFileImage, faImage, faImagePortrait } from '@fortawesome/free-solid-svg-icons';
import { faMicrophone, faStop } from '@fortawesome/free-solid-svg-icons';
import { Radio } from 'react-loader-spinner';
import LoadingIndicator from '../shared/LoadingIndicator';
import alasql from 'alasql';

const CsvDBLoader = ({ tableName = 'importedCSV', uploadedFileUrl = null, compactMode = false, input, onFileSelect, setIsGenerateDisabled, fileTypes, localDb }) => {
  const [queryResult, setQueryResult] = useState([]);
  const [loading, setLoading] = useState();
  const [uploadedFile, setUploadedFile] = useState(uploadedFileUrl);
  const [mediaUrl, setMediaUrl] = useState(uploadedFileUrl)

  const handleFileInput = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    setIsGenerateDisabled(false)

    const reader = new FileReader();
    reader.onload = function (event) {
      let csvContent = event.target.result;
      // Find the index where the actual header starts
      const headerStartIndex = csvContent.indexOf('First Name,Last Name,URL,Email Address,Company,Position,Connected On');
      if (headerStartIndex > -1) {
        // If found, trim the content from this index
        csvContent = csvContent.substring(headerStartIndex);
      }

      alasql.promise(`DROP TABLE IF EXISTS ${tableName}; CREATE TABLE ${tableName}`)
        .then(() => {
          alasql.promise(`SELECT * INTO ${tableName} FROM CSV(?, {headers: true})`, [csvContent])
            .then(() => {
              executeCustomQuery(`SELECT * FROM ${tableName}`); // Automatically display all data after loading
            })
            .catch(error => console.error('Error loading CSV data into AlaSQL table:', error));
        })
        .catch(error => console.error('Error creating table in AlaSQL:', error));
    };
    reader.readAsText(file);
  };
  const executeCustomQuery = async (query = customQuery) => {
    try {
      const result = await alasql.promise(query);
      setQueryResult(result);
      // transformDataToGraph(result, "aa@aa.coms"); // Transform the result into graph data immediately after querying
    } catch (error) {
      console.error('Error executing custom query:', error);
      setQueryResult([]);
    }
  };



  return (

    <>
      {compactMode && (
        <button
          disabled={loading}
          onClick={() => { document.getElementById('file-uploader').click() }}
          className={`flex ml-2 items-center justify-center py-3 px-4 text-sm border-black rounded border-2 text-black  hover:bg-gray-200 transition duration-300 ease-in-out focus:outline-none ${(loading ? 'bg-blue-500' : 'bg-white')}`}

        >
          {loading ? (
            <Radio visible={true} colors={['#ffff', '#ffff', '#ffff']} width="18" height="18" wrapperStyle={{ display: 'inline-block' }} />
          ) : (
            <span className="flex-shrink-0">
              <FontAwesomeIcon color={loading ? 'red' : 'black'} icon={loading ? faStop : faImage} /> {/* Change icon based on recording state */}
            </span>

          )}

        </button>
      )}
      {!loading &&
        <>
          <label hidden={compactMode} className="input-label">{input?.label}</label>

          <input
            hidden={compactMode}
            id='file-uploader'
            key={input?.name || 'file'}
            type="file"
            accept={fileTypes || input?.accept}
            className="file:mr-4 file:py-2 file:px-4 file:border-2 file:border-black file:text-black file:bg-white hover:file:bg-gray-50"
            onChange={(e) => handleFileInput(e, input.name)}
            placeholder={input.placeholder}
          />
          <h3>Connections:</h3>
          <pre>{JSON.stringify(queryResult.length, null, 2)}</pre>
        </>

      }
      {loading && !compactMode && <LoadingIndicator message={'Uploading file..'} />}
      {!loading && uploadedFile && !compactMode && (
        <div className="uploaded-file-indicator">
          <FontAwesomeIcon icon={faCheckCircle} size="lg" color="green" />
          <span className="file-name">{uploadedFile}</span>
          <img width={'128px'} src={mediaUrl} />
        </div>
      )}

    </>


  );
};

export default CsvDBLoader;
