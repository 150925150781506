import React, { useEffect, useState, useContext } from 'react';
import LoadingIndicator from "../components/shared/LoadingIndicator";
import ErrorIndicator from "../components/shared/ErrorIndicator";
import { useNavigate } from 'react-router-dom';
import { useApi } from '../callApi';
import LandingHeader from '../components/shared/LandingHeader';
import { SubscriptionContext } from '../ProtectedRoute'; // Import the SubscriptionContext

import { useLocation } from 'react-router-dom';

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};


const SubscriptionPage = ({ }) => {
  const subscription = useContext(SubscriptionContext); // Access subscription data from the context

  const requiresAction = ['past_due', 'canceled', 'incomplete', 'incomplete_expired', 'ended'].includes(subscription.status);
  const query = useQuery();


  const [message, setMessage] = useState(null);
  const paymentStatus = query.get('payment');

  useEffect(() => {
    switch (paymentStatus) {
      case 'success':
        setMessage('Payment successful! Thank you for your subscription.');
        break;
      case 'cancel':
        setMessage('Payment canceled. You have not been charged.');
        break;
      default:
        if (requiresAction) {
          setMessage('Please activate your subscription to continue using MakeGen.');

        }
        else if (subscription.status === 'new') {
          setMessage('Welcome to MakeGen! Please activate your 7-day trial subscription for the Basic plan. No credit card required!');

        } else
          setMessage(null)
        break;
    }
  }, [paymentStatus]);




  const { callApi } = useApi();
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const pricingTiers = [
    {
      name: "Basic",
      price: "$20",
      per: "month",
      features: [
        "Up to 500 generations",
        "Standard support",
        "Community access",
      ],
      planId: 'price_1OsO59BWfq3XHXwE2IiXzFG7'
    },
    // {
    //   name: "Standard",
    //   price: "$40",
    //   per: "month",
    //   features: [
    //     "Up to 1,200 generations",
    //     "Priority support",
    //     "Community access",
    //     "Extended analytics",
    //   ],
    //   recommended: true,
    // },
    // {
    //   name: "Unlimited",
    //   price: "$100",
    //   per: "month",
    //   features: [
    //     "Unlimited generations",
    //     "24/7 support",
    //     "Dedicated account manager",
    //     "Advanced security features",
    //   ],
    // },
  ];

  const handlePayment = async () => {
    setLoading(true);
    try {
      const response = await callApi('/create-checkout-session', { method: 'POST' });

      if (response.sessionUrl) {
        // Redirect the user to Stripe Checkout
        window.location.href = response.sessionUrl;  // Assuming the API returns the session URL under the key 'sessionUrl'
      } else {
        throw new Error(data.message || 'Failed to create checkout session');
      }
    } catch (error) {
      console.error('Error redirecting to payment:', error);
      setError('Failed to initiate payment process');
    } finally {
      setLoading(false);
    }
  };



  const handleSubscribe = async (planId) => {
    setLoading(true);
    try {
      const response = await callApi(`/user/subscription/create?plan_id=` + planId, {
        method: 'POST',
      }, {
        planId: planId,
      });
      //setSubscription({ ...subscription, ...response, status: 'active' });
      window.location = '/dashboard'
      setLoading(false);
    } catch (error) {
      console.error('Error subscribing to plan:', error);
      setError('Failed to subscribe to plan');
      setLoading(false);
    }
  };

  const handleCancelSubscription = async () => {
    setLoading(true);
    try {
      await callApi(`/user/subscription/cancel?subscription_id=${subscription.subscription_id}`, { method: 'POST' }, {
        subscription_id: subscription.subscription_id
      });
      //setSubscription({ ...subscription, status: 'canceled' });
      window.location.reload()
      setLoading(false);
    } catch (error) {
      console.error('Error canceling subscription:', error);
      setError('Failed to cancel subscription');
      setLoading(false);
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString();
  };

  return (
    <div>
      <LandingHeader />
      {subscription?.status != 'active' &&
        subscription?.status != 'trialing' && (
          <div className="bg-blue-100 border-t-4 border-blue-500 rounded-b text-blue-900 px-4 py-3 shadow-md" role="alert">
            <div className="flex">
              <div className="py-1"><svg className="fill-current h-6 w-6 text-blue-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9 12V9c0-.55.45-1 1-1s1 .45 1 1v3c0 .55-.45 1-1 1s-1-.45-1-1zm0 4c0 .55.45 1 1 1s1-.45 1-1-.45-1-1-1-1 .45-1 1zm1-16C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0z" /></svg></div>
              <div>
                <p className="font-bold">Important Message</p>
                <p className="text-sm">{message}</p>
              </div>
            </div>
          </div>
        )}
      {loading && <LoadingIndicator message="Loading subscription details..." />}
      {error && <ErrorIndicator message={error} onClose={() => setError(null)} />}

      {subscription && !loading && !error && (
        <div className="min-h-screen bg-gray-50 py-12">
          <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="bg-white shadow rounded-lg">
              {subscription.status === 'new' ? (
                <div className="px-4 py-5 sm:p-6 text-center">
                  <h2 className="text-lg leading-6 font-medium text-gray-900">Welcome to MakeGen!</h2>
                  <p className="mt-2 text-sm text-gray-500">Start your 7-day free trial of the <a href="/pricing">Basic plan</a> 20$/month today. No credit card required!</p>

                  <div className="mt-4">
                    <button onClick={() => handleSubscribe('price_1OsO59BWfq3XHXwE2IiXzFG7')} className="px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700">
                      Activate Free Trial
                    </button>
                  </div>
                </div>
              ) : (
                <div className="px-4 py-5 sm:p-6">
                  <h1 className="text-2xl leading-6 font-bold text-gray-900">Subscription Management</h1>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Manage your subscription details below.
                  </p>
                  <div className="mt-6">
                    <div className="overflow-hidden sm:rounded-lg">
                      <div className="border-t border-gray-200">
                        <dl>
                          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Status</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{subscription.status}</dd>
                          </div>
                          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Trial Ends</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{formatDate(subscription.trialEndDate)}</dd>
                          </div>
                        </dl>
                      </div>
                      <div className="px-4 py-3 sm:px-6">
                        {subscription.status !== 'canceled' && subscription.status !== 'ended' && (
                          <button onClick={handleCancelSubscription} className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                            Cancel Subscription
                          </button>
                        )}
                        {subscription.status != 'ended' && subscription.status != 'trialing' &&
                          <div className="mt-10">
                            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-8">
                              {pricingTiers.map((tier) => (
                                <div key={tier.name} className={`relative p-6 border-2 ${tier.recommended ? "border-indigo-500" : "border-gray-300"} rounded-lg bg-white shadow-sm flex flex-col`}>
                                  <dt>
                                    <p className="text-lg leading-6 font-medium text-gray-900">{tier.name}</p>
                                  </dt>
                                  <dd className="mt-2">
                                    <p className="text-3xl font-semibold">{tier.price}</p>
                                    <p className="text-gray-500">{tier.per}</p>
                                  </dd>
                                  <dd className="mt-4">
                                    <ul className="list-disc pl-5 space-y-2">
                                      {tier.features.map((feature) => (
                                        <li key={feature} className="text-gray-500">{feature}</li>
                                      ))}
                                    </ul>
                                  </dd>
                                  <dd className="mt-6">
                                    <button onClick={() => handleSubscribe(tier.planId)} className={`w-full flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white ${tier.recommended ? "bg-indigo-600 hover:bg-indigo-700" : "bg-gray-600 hover:bg-gray-700"}`}>
                                      {tier.price === "Custom" ? "Contact Us" : "Subscribe"}
                                    </button>

                                  </dd>
                                </div>
                              ))}
                            </dl>
                          </div>
                        }
                        {(subscription.status == 'ended' || subscription.status == 'past_due' || subscription.status == 'incomplete_expired') && (
                          <div className="mt-6">
                            <button
                              onClick={handlePayment}
                              className="w-full flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700"
                            >
                              Make a Payment
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

              )}

            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubscriptionPage;
