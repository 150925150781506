import React from 'react';
import { formatDistanceToNow } from 'date-fns';
import { useNavigate } from 'react-router-dom';

const AppRunCard = ({ run }) => {
  const timeAgo = formatDistanceToNow(new Date(run.created_at), { addSuffix: true });
  const navigate = useNavigate();

  const viewResultsHandler = () => {
    navigate(`/user/runs/${run.uuid}`);
  };

  // Thumbnail style
  const thumbnailStyle = {
    width: '50px', // Set your desired thumbnail width
    height: '50px', // Set your desired thumbnail height
    objectFit: 'cover', // This ensures the image covers the area, change as needed
    borderRadius: '50%', // Optional: makes the image round
    marginRight: '1rem', // Add some space between the thumbnail and the text
  };

  return (
    <div className="border bg-white rounded-lg shadow-sm hover:shadow transition-shadow duration-200 ease-in-out">
      <div className="p-4 flex items-center"> {/* Adjusted for flex layout */}
        {/* Conditionally render the thumbnail image if it exists */}
        {run.data && run.data.thumbnailUrl && (
          <img
            src={run.data.thumbnailUrl}
            alt={`${run.title} thumbnail`}
            style={thumbnailStyle}
          />
        )}
        <div>
          <h4 className="text-lg font-medium text-gray-900">{run.title}</h4>
          <div className="text-sm text-gray-500">
            <h2 className="font-medium text-2xl">{run.name}</h2>
            <p>Ran {timeAgo}</p>
          </div>
        </div>
      </div>
      <div className="px-4 py-3 bg-gray-50 rounded-b-lg flex justify-between items-center">
        <img src={`/assets/images/${run.apps?.output_icon}`} alt={run.apps?.output_icon} className="h-8 w-8" />
        <span className="text-sm text-gray-500">{run.apps?.name}</span>
        <button
          className="text-black bg-white border-2 border-black font-medium rounded-lg text-sm px-5 py-2.5 transition-all duration-300 hover:border-yellow-500 hover:shadow"
          onClick={viewResultsHandler}
        >
          View Results
        </button>

      </div>
    </div>
  );
};

export default AppRunCard;
